import React, { useEffect, useState, createRef } from 'react';
import generator from 'generate-password';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Loading } from 'components/Loading';
import signUpRequests from 'services/signUpRequests';
import { getOAuthUser, getAccessfromID } from 'services/oAuthRequests';
import { storeData, logDebug } from 'utils/AuxiliarFunctions';
import { ReportOff } from '@material-ui/icons';

export function OAuthCallback(defaultProvider) {
  const [loading, setLoading] = useState(true);
  const [callbackCode, setCallbackCode] = useState(null);
  const history = useHistory();
  const mobileCallbackAnchorRef = createRef();

  async function logInUser(oAuthUser) {
    if (oAuthUser.access_token) {
      sessionStorage.setItem('userLogged', true);
      const token_expiration = new Date();
      token_expiration.setSeconds(
        token_expiration.getSeconds() + oAuthUser.expires_in,
      );
      await storeData('username', oAuthUser.cpf);
      await storeData('application_token', oAuthUser.access_token);
      await storeData('token', oAuthUser.id_token);
      await storeData('refresh_token', oAuthUser.refresh_token);
      await storeData('Token_exp', token_expiration);
      history.push('/');
    } else throw new Error('Não foi possível fazer login, tente novamente mais tarde ou tente logar com cpf');
  }

  async function signUpAndUpdateUser(oAuthUser) {
    const username = oAuthUser.cpf;
    const phone = oAuthUser.phone_number ? oAuthUser.phone_number : '';
    const { email } = oAuthUser;

    const password = generator.generate({
      numbers: true,
      strict: true,
    });

    // Tenta fazer sign up do usuário
    const signUpResp = await signUpRequests(username, email, phone, password, '')
      .then((response) => response)
      .catch((response) => response);

    if (signUpResp.status === 201) {
      const accessFromID = await getAccessfromID(oAuthUser.id_token);
      return { ...oAuthUser, ...accessFromID };
    }
    return oAuthUser;
  }

  useEffect(() => {
    async function initializeWeb() {
      try {
        let oAuthUser = await getOAuthUser(callbackCode);
        if (oAuthUser.status === 'No User Found') oAuthUser = await signUpAndUpdateUser(oAuthUser);

        await logInUser(oAuthUser);
      } catch (e) {
        handleError(e);
      }
    }

    async function initializeMobile() {
      // mobileCallbackAnchorRef.current.click();
      initializeWeb();
    }

    async function setProvider(provider) {
      await storeData('provider', provider);
    }

    if (callbackCode === null) {
      const url = window.location.href;
      setProvider(defaultProvider);
      
      setCallbackCode(url.split('code=')[1].split('&')[0]);
      logDebug('url: ', url);
    } else if (isMobile) initializeMobile();
    else initializeWeb();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackCode]);

  function handleError(e) {
    setLoading(false);
    logDebug(`Erro ao comunicar com provedor: ${e}`);
  }

  if (loading) {
    return (
      <>
        {/* {isMobile && (
        <a
          href={`fluxmed://open.fluxmed.app/code=${callbackCode}`}
          ref={mobileCallbackAnchorRef}
        >
          Aguardes uns instantes para ser redirecionado automaticamente ou clique aqui.
        </a>
        )} */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
            minHeight: '80vh',
          }}
        >
          <Loading size="large" />
        </div>
      </>
    );
  }
  return <h1>Error</h1>;
}
