import React from 'react';
import { Grid } from '@material-ui/core';

export function NewConsentTerm({ professionalName, professionalCpf, locationService}) {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    let monthString = '';
    switch(month){
        case 1: monthString = 'Janeiro';
        break;
        case 2: monthString = 'Fevereiro';
        break;
        case 3: monthString = 'Março';
        break;
        case 4: monthString = 'Abril';
        break;
        case 5: monthString = 'Maio';
        break;
        case 6: monthString = 'Junho';
        break;
        case 7: monthString = 'Julho';
        break;
        case 8: monthString = 'Agosto';
        break;
        case 9: monthString = 'Setembro';
        break;
        case 10: monthString = 'Outubro';
        break;
        case 11: monthString = 'Novembro';
        break;
        case 12: monthString = 'Dezembro';
        break;
        default:
        break;
    }

    const professional = {
        professionalName: professionalName,
        cpf: professionalCpf,
        place: locationService,
        day: day,
        month: monthString,
        year: year,
    };
    
    return(
        <Grid container>
            <div style={{ 
                border: '1px solid #c8c8c8',
                borderRadius: '30px',
                padding: '50px',
                height: 'auto',
                maxHeight: '53vh',
                overflow: 'auto',
                scrollbarWidth:'none',
                msOverflowStyle:'none'
                }}>
                <div style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '18px',
                }}>
                    <p>Termo de compromisso de confidencialidade de informações e proteção de dados pessoais e sensíveis</p>
                </div>
                <div style={{
                    textAlign: 'justify',
                    fontSize: '14px',
                }}>
                    <p>Este documento visa registrar a manifestação livre, informada e inequívoca pela qual eu, <u>{professional.professionalName}</u>,
                        CPF n° <u>{professional.cpf}</u>, qualidade de PROFISSIONAL DE SAÚDE, concordo com as disposições  do presente 
                        Termo de Compromisso de Confidencialidade de Informações e Proteção de dados Pessoais e Sensíveis, 
                        no âmbito da prestação de serviços de atenção à saúde, seja qual o for vínculo (estatutário, celetista ou prestador de serviço).</p>
                    <p>Responsabilizo-me integralmente pela adequada utilização dos dados dos pacientes a que tiver acesso,  
                        em  razão da utilização das ferramentas tecnológicas da iPeS, ciente de que poderei ter acesso a diversas 
                        informações pessoais, sensíveis, estratégicas, entre outras - confidenciais ou não - armazenadas nos sistemas 
                        informatizados e da possibilidade de vir a ser responsabilizado civil, criminal e administrativamente pelos 
                        danos morais ou materiais decorrentes da utilização, reprodução ou divulgação indevida dos citados dados;</p>
                    <p>Tenho ciência de que as credenciais de acesso (login e senha) são de uso pessoal e intransferível e de conhecimento 
                        exclusivo. É de minha inteira responsabilidade todo e qualquer prejuízo causado pelo fornecimento de minha senha pessoal a terceiros, independente do motivo.</p>
                    <p>Comprometo-me a não utilizar qualquer informação à qual tenha acesso, sem consentimento do titular na estrita 
                        forma da lei, classificada como confidencial ou não, <u>para fins diversos</u> daqueles para os quais tive autorização de acesso, 
                        quais sejam à execução das ações de atenção à saúde (promoção, proteção e ou recuperação da saúde), coordenação, 
                        gestão e regulação do cuidado, planejamento e gestão em saúde, bem como a tutela da saúde dos usuários do SUS, 
                        nos termos da Lei Federal nº 13.709/2018 ("LGPD");</p>
                    <p>Reconheço que para os fins deste documento serão consideradas confidenciais todas as informações, incluindo, 
                        mas não se limitando a: a) Dados pessoais - qualquer informação que possa tornar uma pessoa física identificada 
                        ou identificável; e b) Dados sensíveis - Qualquer dado pessoal que diga respeito a origem racial ou étnica, convicção religiosa, 
                        opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, bem como dado referente à saúde 
                        ou à vida sexual, dado genético ou biométrico.</p>
                    <p>Reconheço que meus dados pessoais utilizados para acesso à Plataforma iPeS serão conservados durante o tempo que 
                        estiver vigente a relação contratual ou estatutária com a instituição a qual estou vinculado e após esta finalizar, 
                        durante os períodos de retenção de dados legalmente exigíveis, de forma estritamente necessária, garantida as condições 
                        bem como o exercício dos direitos previstos na Lei Federal nº 13.709/2018 ("LGPD") na vigência da relação contratual assim 
                        como após o término da referida relação.</p>
                    <p>Reconheço, neste ato, ter lido, compreendido e sanado todas as dúvidas sobre o Termo De Compromisso De Confidencialidade De Informação E Proteção De Dados Pessoais e sensíveis.</p>
                </div>
                <div style={{
                    textAlign: 'center',
                    fontSize: '14px',
                }}>
                    <p><u>{professional.place}</u>, <u>{professional.day}</u> de <u>{professional.month}</u> de <u>{professional.year}</u></p>
                    <p> (local e data)</p>
                    <br />
                    {/* <u>_______________________</u>
                    <p>assinatura</p> */}
                </div>
            </div>
        </Grid>
    );
}