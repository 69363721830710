import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

import MHDRequests from 'services/MHDRequests';
import { oids } from 'configs';
import { useUserContext } from 'store';
import {
  CNSisValid,
  verifyCPF,
} from 'utils/AuxiliarFunctions';
import i18n from 'i18n';
import { fixTimezoneOffset } from 'utils/dateFunctions';
import { useHistory } from 'react-router-dom';

const initialState = {
  patient: {
    name: 'Não Encontrado',
    birthdate: 'NA',
    gender: 'NA',
    patientId: '',
    patientCNS: 'Não Encontrado',
    socialName: '',
    organizationId: '',
  },
  subject: {
    subjectId: '',
    subjectToken: '',
    subjectRole: '',
  },
  purposeOfUse: 'Atendimento',
  loading: true,
};

const VisualizerContext = createContext(initialState);

export function VisualizerContextProvider({ children, urlInput }) {
  const [state, setState] = useState(initialState);
  const [user] = useUserContext();
  const history = useHistory();

  function resetVisualizerState() {
    setState({
      patient: {
        name: user.name,
        birthdate: user.birthday,
        gender: user.gender,
        patientId: user.cpf,
        socialName: user.socialName ? user.socialName : '',
        patientCNS: user.cns,
        organizationId: '',
      },
      subject: {
        subjectId: user.cpf,
        subjectRole: user.role,
        subjectToken: '',
      },
      purposeOfUse: 'Atendimento',
      loading: false,
    });
  }

  async function setVisualizerProfessionalPlaces({
    patientId, orgId, purposeOfUse, subjectId,
    subjectToken,
    subjectRole,
  }) {
    let patientOID;
    if (CNSisValid(patientId)) patientOID = oids.cns;
    else if (verifyCPF(patientId)) patientOID = oids.cpf;
    const defaultSubjectId = urlInput ? '' : user.cpf;

    try {
      const patientJSON = await MHDRequests.getProfessionalPlaces({
        patientId,
        subjectToken,
        orgId,
        purposeOfUse,
        subjectId,
        subjectRole,
        patientOID,
      });

      setState({
        patient: {
          name: patientJSON.name.find((nameObj) => nameObj.use === 'official').text,
          birthdate: fixTimezoneOffset(new Date(patientJSON.birthDate)).toLocaleDateString(i18n.language),
          gender: patientJSON.gender,
          patientId: patientJSON.identifier.find((idObj) => idObj.system.includes(oids.cns)).value,
          socialName: '',
          patientCNS: patientJSON.identifier.find((idObj) => (
            idObj.system.includes(oids.cns) && idObj.use === 'official'
          )).value,
          organizationId: orgId || '',
        },
        subject: {
          subjectId: subjectId || defaultSubjectId,
          subjectToken: subjectToken || '',
          subjectRole: subjectRole || '',
        },
        purposeOfUse: purposeOfUse || 'Atendimento',
        loading: false,
      });
    } catch (err) {
      setVisualizerLoading(false);
    }
    history.push('/registros');
  }

  async function setVisualizerPatient({
    patientId, 
    orgId, 
    purposeOfUse, 
    subjectId,
    subjectToken,
    subjectRole,
  }) {
    let patientOID;
    if (CNSisValid(patientId)) patientOID = oids.cns;
    else if (verifyCPF(patientId)) patientOID = oids.cpf;
    const defaultSubjectId = urlInput ? '' : user.cpf;

    try {
      const patientJSON = await MHDRequests.getPatientJson({
        patientId,
        subjectToken,
        orgId,
        purposeOfUse,
        subjectId,
        subjectRole,
        patientOID,
      });
      
      setState({
        patient: {
          name: patientJSON.name.find((nameObj) => nameObj.use === 'official').text,
          birthdate: fixTimezoneOffset(new Date(patientJSON.birthDate)).toLocaleDateString(i18n.language),
          gender: patientJSON.gender,
          patientId: patientJSON.identifier.find((idObj) => idObj.system.includes(oids.cns)).value,
          socialName: '',
          patientCNS: patientJSON.identifier.find((idObj) => (
            idObj.system.includes(oids.cns) && idObj.use === 'official'
          )).value,
          organizationId: orgId || '',
        },
        subject: {
          subjectId: subjectId || defaultSubjectId,
          subjectToken: subjectToken || '',
          subjectRole: subjectRole || '',
        },
        purposeOfUse: purposeOfUse || 'Atendimento',
        loading: false,
      });
    } catch (err) {
      setVisualizerLoading(false);
    }
    history.push('/registros');
  }

  function setVisualizerLoading(loading) {
    setState((prevState) => ({ ...prevState, loading }));
  }

  const contextValue = {
    visualizerState: state,
    setVisualizerState: setState,
    setVisualizerPatient,
    setVisualizerProfessionalPlaces,
    resetVisualizerState,
    setVisualizerLoading,
  };

  useEffect(() => {
    if (urlInput) {
      const urlString = window.location.href;
      if (urlString.includes('?')) {
        const urlParams = urlString.split('?')[1].split('&');
        const paramKeyValue = {};
        urlParams.forEach((param) => {
          const key = param.split('=')[0];
          const value = param.split('=')[1];
          paramKeyValue[key] = value;
        });

        setVisualizerPatient({
          patientId: paramKeyValue['resource-id'],
          orgId: paramKeyValue['organization-id'],
          purposeOfUse: paramKeyValue.purposeofuse,
          subjectId: paramKeyValue['subject-id'],
          subjectToken: paramKeyValue['token'],
          subjectRole: paramKeyValue['role'],
        });
      } else setVisualizerLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlInput]);

  return (
    <VisualizerContext.Provider value={contextValue}>
      {children}
    </VisualizerContext.Provider>
  );
}

export function useVisualizerContext() {
  return useContext(VisualizerContext);
}

VisualizerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  urlInput: PropTypes.bool,
};

VisualizerContextProvider.defaultProps = {
  urlInput: false,
};
