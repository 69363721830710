import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
} from '@material-ui/core';
import { logDebug } from 'utils/AuxiliarFunctions';
import { useTranslation } from 'react-i18next';

function ObservationTabContent({ documents, selectReport }) {
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [init, setInit] = useState(false);
  const { t } = useTranslation('PatientSummary');

  const extractValueQuantity = (document) => {
    let value = '';
    try {
      value = document.valueQuantity;
      if (!value) {
        const pressure = {
          sys: '',
          dias: '',
          unit: '',
        };
        document.component.forEach((item) => {
          const { code } = item.code.coding[0];
          if (code === '8480-6') pressure.sys = item.valueQuantity.value;
          else if (code === '8462-4') pressure.dias = item.valueQuantity.value;
          if (item?.valueQuantity?.unit) {
            pressure
              .unit = item.valueQuantity.unit;
          }
        });
        value = `${pressure.sys}/${pressure.dias} ${pressure.unit}`;
      } else value = `${value.value} ${value.unit}`;
    } catch (err) {
      logDebug('observationTabContent :>>', err);
      value = '';
    }

    return value;
  };

  const initialize = async () => {
    const createRows = documents.map((document) => {
      const observationType = document.code.coding[0];
      const { code } = observationType;
      const value = extractValueQuantity(document);
      let header = '';
      switch (code) {
        case '55284-4':
          header = t('ObservationTabContent-patientSummary-healthSummary-bloodPressure');
          break;
        case '29463-7':
          header = t('ObservationTabContent-patientSummary-healthSummary-weight');
          break;
        case '8302-2':
          header = t('ObservationTabContent-patientSummary-healthSummary-height');
          break;
        default:
          break;
      }
      return ({
        id: document.id,
        description: value,
        source: document.meta.tag ? document.meta.tag[0].code : document.meta.source,
        header,
      });
    });

    const createHeaders = createRows.map((item) => item.header);
    setHeaders(createHeaders);
    setRows(createRows);
  };

  useEffect(() => {
    if (documents) initialize().then(() => setInit(true));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  if (!init) return null;

  return (
    <Table
      size="small"
      aria-label="enhanced table"
    >
      <TableHead>
        <TableRow>
          {headers.map((header, i) => (
            <TableCell
              size="small"
              align="center"
              padding="checkbox"
              key={i}
            >
              <h5>
                {header}
              </h5>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {
            rows.map((document) => (
              <Tooltip
                placement="top"
                arrow
                key={document.id}
                title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
              >
                <TableCell
                  className="observationTabContent-document"
                  size="small"
                  align="center"
                  onClick={() => selectReport(document.source)}
                >
                  {document.description}
                </TableCell>
              </Tooltip>
            ))
          }
        </TableRow>
      </TableBody>
    </Table>
  );
}

ObservationTabContent.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectReport: PropTypes.func.isRequired,
};

export { ObservationTabContent };
