import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loading, NoReports } from 'components';
import { logDebug } from 'utils/AuxiliarFunctions';
import MHDRequests from 'services/MHDRequests';
import { useTranslation } from 'react-i18next';
import { tabsInfoArray, findTabIdFromUrl } from './TabsInfo';
import { SummaryTab } from './SummaryTab';
import './PatientSummary.css';

function PatientSummary({
  patientId, 
  selectReport, 
  purposeOfUse, 
  subjectId,
  subjectToken,
  subjectRole,
  organizationId
}) {
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState({});
  const { t } = useTranslation('PatientSummary');

  const extractDocumentTypesFromBundleEntries = (bundleResponse) => {
    const documentGroupTypes = {};
    let bundleEntries = bundleResponse.entry;
    bundleEntries = bundleEntries
      .filter((document) => (
        (document.response.status === '200 OK')
          && ((document.resource.total !== 0))
          ));
    try {

      bundleEntries.forEach((documentGroupElement) => {
        const documentGroup = documentGroupElement?.resource;
        if (!documentGroup) return {};
        const source = documentGroup.link[0].url;
        const tabId = findTabIdFromUrl(source);
        if (tabId) {
          documentGroupTypes[tabId] = documentGroupTypes[tabId] ? [
            ...documentGroupTypes[tabId],
            documentGroup,
          ] : [documentGroup];
        }
        return null;
      });
    } catch (err) {
      logDebug('PatientSummary extractDocumentTypesInfoBundleEntries', err);
    }
    return documentGroupTypes;
  };

  const preloadSummaryDocs = async () => {
    let newDocuments = { ...documents };
    try {
      const allDocumentsBundle = await MHDRequests.getPatientSummary({
        patientId,
        organizationId,
        purposeOfUse,
        subjectId,
        subjectToken,
        subjectRole,
      });
      const documentsTypesArray = allDocumentsBundle?.entry;

      if (!allDocumentsBundle
        || !documentsTypesArray
        || documentsTypesArray === 'network_error') return null;

      newDocuments = {
        ...extractDocumentTypesFromBundleEntries(allDocumentsBundle),
      };
    } catch (err) {
      logDebug('PatientSummary preloadSummaryDocs :>> ', err);
    }
    setDocuments(newDocuments);
    return null;
  };

  useEffect(() => {
    const initialize = async () => {
      await preloadSummaryDocs();
    };

    initialize().then(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="patientSummary-container">
        <Loading size="large" />
      </div>
    );
  }

  const isEmptyJson = (object) => {
    try {
      return JSON.stringify(object) === JSON.stringify({});
    } catch (err) {
      logDebug('PatientSummary isEmptyJson', err);
    }
    return false;
  };

  return (
    <div className="patientSummary-container">
      {/* <div className="patientSummary-header">
        <h1>
          {t('PatientSummary-healthSummary-title')}
        </h1>
      </div> */}
      {
        isEmptyJson(documents) && (<NoReports />)
      }
      {
        !isEmptyJson(documents)
         && tabsInfoArray().map((tab) => (documents[tab.id]?.length ? (
           <SummaryTab
             patientId={patientId}
             organizationId={organizationId}
             key={tab.id}
             tab={tab}
             selectReport={selectReport}
             initialDocuments={documents[tab.id]}
             subjectToken={subjectToken}
           />
         ) : null))
      }
    </div>
  );
}

PatientSummary.propTypes = {
  patientId: PropTypes.string.isRequired,
  selectReport: PropTypes.func.isRequired,
  purposeOfUse: PropTypes.string,
};

PatientSummary.defaultProps = {
  purposeOfUse: 'Atendimento',
};

export default PatientSummary;
