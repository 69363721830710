import React, { useEffect, useState } from 'react';
import { 
    Box, 
    Button, 
    Paper, 
    Typography 
} from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

import useStyles from './styles';
import AdminFhirRequests from 'services/AdminFhirRequests';

import { Loading, NoReports } from 'components';
import ReportModal from '../ReportModal/ReportModal';

import { translate } from 'utils/AuxiliarFunctions';

const WaitListTab = ({ patientId }) => {
    const classes = useStyles();
        
    const [is_loading, setIsLoading] = useState(false);
    const [wait_list, setWaitList] = useState([]);
    const [modal_open, setModalOpen] = useState(false);
    const [current_task, setCurrentTask] = useState(null);

    const getData = async () => {
        setIsLoading(true);
        const resp = await AdminFhirRequests.getTasks(patientId);
        if (resp?.entry) {
            const data = resp.entry.map((entry) => entry.resource);
            setWaitList(data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        (async () => await getData())();
    }, [])

    const getProcedure = (input) => {
        const procedure = input.find((item) => item.type.coding[0].code === "procedimento");
        if (procedure) {
            return `${procedure.valueCodeableConcept.coding[0].code} - ${procedure.valueCodeableConcept.coding[0].display}`
        }

        return '';
    }

    const getModality = (input) => {
        const modality = input.find((item) => item.type.coding[0].code === "modalidadeAssistencial");

        if (modality) {
            return modality.valueCodeableConcept.coding[0].display;
        }

        return '';
    }

    const getDataByCode = (input, code, valueReference, cotableReference = undefined) => {
        if (input) {
            const data = input.find((item) => item.type.coding[0].code === code);
    
            if (data) {
                if (valueReference === "valueCodeableConcept" && cotableReference) {
                    return data[valueReference].coding[0][cotableReference];
                }
                return data[valueReference]
            }
        }

        return '';
    }

    return (
        <>
            <Box>
                {is_loading && <Loading type="linear" />}
                {(wait_list.length === 0 && !is_loading) 
                    ? <NoReports description="O paciente não possui listas de espera" />
                    : wait_list.map((item) => (
                        <Button 
                            style={{width: '100%'}}
                            onClick={() => {
                                setModalOpen(true);
                                setCurrentTask(item);
                            }}
                        >
                            <Paper
                                className={classes.paper}
                            >
                                <Typography 
                                    variant='body2'
                                    className={classes.title}
                                >
                                    {getProcedure(item.input)}
                                </Typography>
                                <Typography 
                                    variant='body2'
                                >
                                    Modalidade Assistencial: {getModality(item.input)}
                                </Typography>
                                <Typography 
                                    variant='body2'
                                >
                                    Data: {moment(item.authoredOn).format('DD/MM/YYYY [-] HH:mm')}
                                </Typography>
                                <Typography 
                                    variant='body2'
                                >
                                    Status: {translate(item.businessStatus?.coding[0].code || '')}
                                </Typography>
                            </Paper>
                        </Button>
                    ))
                }
            </Box>
            <ReportModal 
                show={modal_open}
                setShow={setModalOpen}
                title="Detalhes"
                body={(
                    <div className={classes.bodyWrapper}>
                        <Row>
                            <Col xs={4}>
                                <Typography 
                                    variant='body1'
                                >
                                    Modalidade Assistencial
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                    {current_task ? getModality(current_task.input) : ''}
                                </Typography>
                            </Col>
                            {current_task?.reasonCode?.coding[0] && (
                                <>
                                    <Col xs={4}>
                                        <Typography 
                                            variant='body1'
                                        >
                                            Motivo da Solicitação
                                        </Typography>
                                    </Col>
                                    <Col xs={8}>
                                        <Typography
                                            className={classes.detailData}
                                            variant='body2'
                                        >
                                            {current_task?.reasonCode?.coding[0].code} - {current_task?.reasonCode?.coding[0].display}
                                        </Typography>
                                    </Col>
                                </>
                            )}
                            <Col xs={4}>
                                <Typography 
                                    variant='body1'
                                >
                                    Número da Solicitação
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                    {current_task?.identifier[0]?.value || ''}
                                </Typography>
                            </Col>
                            <Col xs={4}>
                                <Typography 
                                    variant='body1'
                                >
                                    Procedimento
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                    {current_task ? getProcedure(current_task.input) : ''}
                                </Typography>
                            </Col>
                            <Col xs={4}>
                                <Typography 
                                    variant='body1'
                                >
                                    Status
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                    {translate(current_task?.businessStatus?.coding[0].code || '')}
                                </Typography>
                            </Col>
                            <Col xs={4}>
                                <Typography 
                                    variant='body1'
                                >
                                    Data da Solicitação
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                    {current_task ? moment(current_task.authoredOn).format('DD/MM/YYYY [-] HH:mm') : ''}
                                </Typography>
                            </Col>
                            <Col xs={4}>
                                <Typography 
                                    variant='body1'
                                >
                                    Data da Última Alteração
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                    {current_task ? moment(current_task.lastModified).format('DD/MM/YYYY [-] HH:mm') : ''}
                                </Typography>
                            </Col>
                            <Col xs={4}>
                                <Typography 
                                    variant='body1'
                                >
                                    Data da Aprovação
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                    {(current_task && getDataByCode(current_task.input, "dataAutorizacao", "valueDateTime")) ? moment(getDataByCode(current_task.input, "dataAutorizacao", "valueDateTime")).format('DD/MM/YYYY [-] HH:mm') : ''}
                                </Typography>
                            </Col>
                            <Col xs={4}>
                                <Typography 
                                    variant='body1'
                                >
                                    Código de Autorização
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                    {current_task ? getDataByCode(current_task.input, "codigoAutorizacao", "valueCodeableConcept", "code") : ''}
                                </Typography>
                            </Col>
                            <Col xs={4}>
                                <Typography 
                                    variant='body1'
                                >
                                    Data de criação do agendamento
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                {(current_task && getDataByCode(current_task.output, "dataCriacaoAgendamento", "valueDateTime")) ? moment(getDataByCode(current_task.output, "dataCriacaoAgendamento", "valueDateTime")).format('DD/MM/YYYY [-] HH:mm') : ''}
                                </Typography>
                            </Col>
                            <Col xs={4}>
                                <Typography 
                                    variant='body1'
                                >
                                    Data do agendamento
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                {(current_task && getDataByCode(current_task.output, "dataAgendamento", "valueDateTime")) ? moment(getDataByCode(current_task.output, "dataAgendamento", "valueDateTime")).format('DD/MM/YYYY [-] HH:mm') : ''}
                                </Typography>
                            </Col>
                            <Col xs={4}>
                                <Typography 
                                    variant='body1'
                                >
                                    Data de execução
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                {(current_task && getDataByCode(current_task.output, "dataExecucao", "valueDateTime")) ? moment(getDataByCode(current_task.output, "dataExecucao", "valueDateTime")).format('DD/MM/YYYY [-] HH:mm') : ''}
                                </Typography>
                            </Col>
                            {current_task?.owner && (
                                <>
                                    <Col xs={4}>
                                        <Typography 
                                            variant='body1'
                                        >
                                            Central de Regulação
                                        </Typography>
                                    </Col>
                                    <Col xs={8}>
                                        <Typography
                                            className={classes.detailData}
                                            variant='body2'
                                        >
                                            {current_task?.owner?.display}
                                        </Typography>
                                    </Col>
                                </>
                            )}
                            {current_task?.requester && (
                                <>
                                    <Col xs={4}>
                                        <Typography 
                                            variant='body1'
                                        >
                                            Unidade Solicitante
                                        </Typography>
                                    </Col>
                                    <Col xs={8}>
                                        <Typography
                                            className={classes.detailData}
                                            variant='body2'
                                        >
                                            {current_task.requester?.display}
                                        </Typography>
                                    </Col>
                                </>
                            )}
                            {current_task?.performerType?.[0]?.coding?.[0] && (
                                <>
                                    <Col xs={4}>
                                        <Typography 
                                            variant='body1'
                                        >
                                            Especialidade
                                        </Typography>
                                    </Col>
                                    <Col xs={8}>
                                        <Typography
                                            className={classes.detailData}
                                            variant='body2'
                                        >
                                            {current_task.performerType[0].coding[0].code} - {current_task.performerType[0].coding[0].display}
                                        </Typography>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </div>
                )}
            />
        </>
    )
}

export default WaitListTab