import React from 'react';

import { Button } from '@material-ui/core';
import i18n from "i18n";
import { useTranslation } from "react-i18next";

import './NewPatientsList.css';

export function NewPatientsList({ patientsToList, selectPatient }) {
    const { t } = useTranslation('NewPatientsList', { i18n });

    return (
        <div className="newPatientList-container">
            <div className="newPatientList-totalPatients">{t('NewPatientsList-patientsTotal')}: {patientsToList.length}</div>
            {patientsToList.length > 0 && 
            <table>
                <tr>
                    <th>{t('NewPatientsList-patientName')}</th>
                    <th>CNS</th>
                    <th>{t('NewPatientsList-patientBirthdate')}</th>
                    <th>{t('NewPatientsList-patientGender')}</th>
                    <th></th>
                </tr>
                {patientsToList.map((patient) => (
                    <tr>
                        <td>{patient.name}</td>
                        <td>{patient.identifier}</td>
                        <td>{patient.birthdate}</td>
                        <td>{patient.gender == 'male' ? t('NewPatientsList-patientGenderMale') : t('NewPatientsList-patientGenderFemale')}</td>
                        <td><Button 
                                variant='contained'
                                color='primary'
                                style={{ background: '#08824A' }}
                                onClick={(e) => {
                                    selectPatient(patient.identifier);
                                }}>{t('NewPatientsList-patientSelect')}</Button></td>
                    </tr>
                ))}

            </table>
            }
        </div>
    );

}