import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import 'semantic-ui-css/semantic.min.css';
import { MenuItem } from 'components';
import logo from 'theme/logo.png';
import { useTranslation } from 'react-i18next';

import { DeployVersion } from 'components/DeployVersion';

const NewPrincipalHeaderLateral = ({ optionsMenu }) => {
  const history = useHistory();
  const [activeItem, setActiveItem] = useState();
  const [showName, setShowName] = useState(false);
  const { t } = useTranslation('PageMask');

  async function handleItemClick(option) {
    setActiveItem(option.id);
    await option.onAccess().then(() => history.push(option.path));
  }

  return (
    <Menu
      compact
      // xs
      secondary
      icon
      vertical
      className="menuCompact menu-small-screen"
      style={{ transition: '0.3s', display: 'flex', marginTop: '4rem', height: '100%' }}
      onMouseEnter={() => {
        setShowName(true);
      }}
      onMouseLeave={() => {
        setShowName(false);
      }}
    >

      {optionsMenu.map((option, i) => (
        <MenuItem
          key={`menu-item-${option.id}-${i}`}
          showName={showName}
          activeItem={activeItem === option.id}
          tooltip={t(option.name)}
          name={option.id}
          iconName={option.iconName}
          handleItemClick={async () => handleItemClick(option)}
        />
      ))}
      <DeployVersion variant="header-bottom-lateral" />
    </Menu>
  );
};

NewPrincipalHeaderLateral.propTypes = {
  optionsMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      onAccess: PropTypes.func.isRequired,
      active: PropTypes.bool.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export { NewPrincipalHeaderLateral };
