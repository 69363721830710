import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { TextField, MenuItem } from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import { changeOrg, loadOrgs } from 'store/actions/professionalOrgActions';
import { loadOrgsProfessional, fetchUserData } from 'utils/userFunctions';
import { retrieveData } from 'utils/AuxiliarFunctions';
import MHDRequests from 'services/MHDRequests';

import './DropdownOrganization.css';

function DropdownOrganization({ showTitle, style }) {

    const { t } = useTranslation('DropdownOrganization', { i18n });
    const dispatch = useDispatch();
    const selectedId = useSelector(state => state.selectedId);
    const orgs = useSelector(state => state.orgs);
  
    useEffect(() => {
        async function load() {
            const username = await retrieveData('username');
            const userMhd = await MHDRequests.getPatientJson({patientId: username});
            const userData = await fetchUserData();

            let rolesOrgs = userData.rolesPerOrganization;
            
            rolesOrgs.unshift({id: "0", organizationName: t('DropdownOrganization-placeholder'), organizationOid: ""});
            
            for (var i = 0; i < rolesOrgs.length; i++) {
                let roleOrg = rolesOrgs[i];
                if (roleOrg.id != 0) {
                    let orgFullInfo = await MHDRequests.getOrganization(rolesOrgs[i].id);
                    let orgOid = orgFullInfo.id.split("-")[0];
                    roleOrg.organizationOid = orgOid;
                }
            }

            const orgsLocal = await loadOrgsProfessional();
            dispatch(loadOrgs(rolesOrgs));
        }
        
        if (orgs.length > 0) {
          // change name only 
          orgs[0].organizationName = t('DropdownOrganization-placeholder');
        } else {
          load();          
        }
    }, [t]);

    return (
        <div className="dropdownorganization" style={style}>
            {showTitle ? <span className="dropdownorganization-title">{t('DropdownOrganization-org')}</span> : <></>} 
            <TextField
                select
                value={selectedId}
                placeholder="Placeholder"
                variant="outlined"
                onChange={(event) => {
                    let selectedId = event.target.value;
                    dispatch(changeOrg(selectedId));
                    }}
                >
                    { orgs.map((org) => (
                    <MenuItem key={`key-${org.id}`} value={org.id}>
                        {org.organizationName}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    )
}

DropdownOrganization.defaultProps = {
    showTitle: false
}

DropdownOrganization.propTypes = {
    showTitle: PropTypes.bool,
    style: PropTypes.node
}

export { DropdownOrganization };