import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import i18n from 'i18n';
import { Button, SimpleModal } from 'components';
import { useVisualizerContext } from 'contexts/VisualizerContext';

export default function EmergencyAccessButton({ patientIdEmergency }) {
  const { setVisualizerPatient } = useVisualizerContext();
  const [showEmergencyModal, setShowEmergencyModal] = useState(false);
  const history = useHistory();
  const { t } = useTranslation('SearchPatient', { i18n });

  async function onClickEmergency() {
    await onClickOrg(patientIdEmergency);
  }

  async function onClickOrg() {
    setVisualizerPatient({ patientId: patientIdEmergency, purposeOfUse: 'Emergencia', orgID: 'teste' });
  }

  const emergencyModal = () => (
    <SimpleModal
      fullWidth={false}
      noCancelButton
      setModal={() => setShowEmergencyModal(false)}
      modal={{
        title: t('EmergencyAccessButton-modalTitle'),
        body: (
          <p>
            {t('EmergencyAccessButton-modalBody')}
          </p>),
        footer: (
          <>
            <Button
              variant="contained"
              color="default"
              onClick={() => setShowEmergencyModal(false)}
            >
              {t('EmergencyAccessButton-modalButtons-close')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onClickEmergency()}
            >
              {t('EmergencyAccessButton-modalButtons-continue')}
            </Button>
          </>),
        visible: showEmergencyModal,
      }}
    />
  );

  return (
    <div className="searchPage_emergency">
      <Button variant="contained" color="secondary" onClick={() => setShowEmergencyModal(true)}>
        {t('EmergencyAccessButton-buttonName')}
      </Button>
      {emergencyModal()}
    </div>
  );
}

EmergencyAccessButton.propTypes = {
  patientIdEmergency: PropTypes.string.isRequired,
};
