import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
} from '@material-ui/core';
import { formatDateDisplay } from 'utils/dateFunctions';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';

function ExamResultsTabContent({ documents, selectReport }) {
  const { t } = useTranslation('PatientSummary');
  const headers = [t('myRegisters-patientSummary-healthSummary-tab-column-code'), t('myRegisters-patientSummary-healthSummary-tab-column-description'), t('myRegisters-patientSummary-healthSummary-tab-column-date')];
  const rows = documents.map((document) => ({
    id: document.id,
    description: document.extension[1].valueString,
    code: document.extension[0].valueString,
    source: `${document.masterIdentifier.value}${document.meta.tag ? document.meta.tag[0].code : document.meta.source}`,
    date: formatDateDisplay(document.context.period.start),
  }));

  return (
    <Table
      size="small"
      aria-label="enhanced table"
    >
      <TableHead>
        <TableRow>
          {headers.map((header, i) => (
            <TableCell
              key={i}
            >
              <h5>
                {header}
              </h5>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map((document) => (
            <Tooltip
              key={document.id}
              placement="top"
              arrow
              title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
            >
              <TableRow
                hover
                onClick={() => {
                  try {
                    selectReport(document.source);
                  }
                  catch(err) {
                    alert(err);
                  }
                }}
              >
                <TableCell padding="checkbox">{document.code}</TableCell>
                <TableCell>{document.description}</TableCell>
                <TableCell padding="checkbox">{document.date}</TableCell>
              </TableRow>
            </Tooltip>
          ))
        }
      </TableBody>
    </Table>
  );
}

ExamResultsTabContent.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectReport: PropTypes.func.isRequired,
};

export { ExamResultsTabContent };
