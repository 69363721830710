import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import { features } from 'configs';
import i18n from 'i18n';
import {
  Loading, Button, DialogUserAddress, SimpleModal,
} from 'components';
import {
  storeData,
  capitalize_first_letters,
  formatName,
  retrieveData,
  checkRegisterType,
  logDebug,
} from 'utils/AuxiliarFunctions';
import { formatDateDisplay } from 'utils/dateFunctions';
import { useUserContext } from 'store/UserContext';
import userRequests from 'services/userRequests';
import profileRequests from 'services/profileRequests';
import { checkResponseFhir } from 'utils/fhirParser';

import firebaseApp from '../SignUp/firebase';
import { RecoverPass } from '../RecoverPass/RecoverPass';

import './ProfilePage.css';

export default function ProfilePage() {
  const [email, setEmail] = useState('');
  const [oldEmail, setOldEmail] = useState('');
  const [patient_id, setPatient_id] = useState('');
  const [name, setName] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [phone, setPhone] = useState('');
  const [oldPhone, setOldPhone] = useState('');
  const [editingProfile, setEditingProfile] = useState(false);
  const [gender, setGender] = useState('');
  const [updating, setUpdating] = useState(false);
  const [passRecovery, setPassRecovery] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [dataAlert, setDataAlert] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showPhoneVerication, setShowPhoneVerication] = useState(false);
  const [showIncorrectCodeModal, setShowIncorrectCodeModal] = useState(false);
  const [socialName, setSocialName] = useState(null);
  const [tryAgainVisible, setTryAgainVisible] = useState(false);
  const [showModalAddress, setShowModalAddress] = useState(false);
  const [captcha, setCaptcha] = useState({});
  const [identifier, setIdentifier] = useState([]);
  const [error, setError] = useState({
    message: '',
    visible: false,
  });
  const [token, setToken] = useState(null);
  
  const [user] = useUserContext();
  const { t } = useTranslation('Profile', { i18n });

  const modalBody = {
    title: dataAlert[0],
    body: dataAlert[1],
    footer: '',
    visible: showAlert,
    cancelButton: 'Fechar',
  };

  useEffect(() => {
    async function asyncCalls() {
      await onRefresh();
    }
    asyncCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onRefresh() {
    setRefreshing(true);
    await getProfileData();
    setRefreshing(false);
  }

  async function getProfileData() {
    try {
      await getPatientAddress();
      const userData = user?.userData;
      const patientJSON = userData.mhd;
      const apimJSON = userData.apim;
      if (typeof patientJSON === 'object') if (patientJSON.name[1]) setSocialName(patientJSON.name[1].text);

      if (typeof apimJSON === 'string') {
        setTryAgainVisible(true);
        setError({
          visible: true,
          message: 'Houve um erro ao tentar carregar os dados do seu perfil.',
        });
        return;
      }
      setTryAgainVisible(false);
      setError({ visible: false });
      await setData(apimJSON, patientJSON);
    } catch (err) {
      logDebug('err', err);
    }
  }

  async function setData(apimJSON, fhirJSON) {
    
    try {
      let auxName = user?.name;
      auxName = capitalize_first_letters(auxName.toLowerCase());
      auxName = formatName(auxName);
      let auxPhone = '';
      let entitlements = null;
      let auxGender = '';
      if (apimJSON.Resources) {
        auxPhone = apimJSON.Resources[0]?.phoneNumbers[0]?.value;
        // eslint-disable-next-line prefer-destructuring
        entitlements = apimJSON.Resources[0]?.entitlements;
        auxGender = apimJSON.Resources[0]?.EnterpriseUser?.manager.value;
        setPatient_id(apimJSON.Resources[0]?.id);
        setEmail(apimJSON.Resources[0]?.emails[0]);
        setOldEmail(apimJSON.Resources[0]?.emails[0]);
      }
  
      let auxBirthday = user?.birthday;
      auxBirthday = formatDateDisplay(auxBirthday, true);
      let organizationIds = [];
      
      if (entitlements) {
        for (const index in entitlements) {
          organizationIds = apimJSON.Resources[0].entitlements[index].value;
        }
      }
      await storeData('cnes', organizationIds);
  
      let ids = fhirJSON?.identifier;
      ids = Array.isArray(ids) ? ids : [];
      setPhone(auxPhone);
      setOldPhone(auxPhone);
      setGender(auxGender);
      setName(auxName);
      setBirthday(auxBirthday);
      setIdentifier(
        ids.filter((auxIdentifier) => {
          if (!auxIdentifier?.use) return true;
          return auxIdentifier.use === 'official';
        }),
      );
    } catch (err) {
      err.name = 401;
      logDebug(err);

      throw err;
    }
  }

  async function getPatientAddress() {
    const userID = await retrieveData('username');

    try {
      const responseUser = await userRequests.getPatient(userID);
      checkResponseFhir(responseUser);
    } catch (err) {
      logDebug(err);
    }
  }

  async function updateProfile() {
    const condChange = phone !== oldPhone || email !== oldEmail;
    if (condChange) {
      const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'editPhone',
        {
          size: 'invisible',
          callback() {},
          'expired-callback': function () {},
        },
      );
      setCaptcha(recaptchaVerifier);
      sendSms(phone, recaptchaVerifier);
      setShowPhoneVerication(true);
    }
    setUpdating(false);
  }

  async function finalizeEdition() {
    const condChange = phone !== oldPhone || email !== oldEmail;
    if (condChange) {
      await updateProfileData();
      setOldPhone(phone);
      setOldEmail(email);
    }
    setUpdating(false);
  }

  async function updateProfileData() {
    setUpdating(true);

    await profileRequests
      .setProfileData(patient_id, phone, email)
      .then((resp) => {
        setShowPhoneVerication(false);
        setShowAlert(true);
        setDataAlert([
          t('ProfilePage-tokenModalSucces-title'),
          t('Seus dados foram atualizados'),
        ]);
        logDebug(
          t('ProfilePage-tokenModalSucces-title'),
          t('Seus dados foram atualizados'),
          resp,
        );
      })
      .catch((err) => {
        logDebug(`Error: ${err}`);
      });
    if (typeof response === 'string') logDebug('Erro ao alterar dados do perfil', 'Tente novamente mais tarde');

    await getProfileData();
    setUpdating(false);
  }

  async function edit() {
    setEditingProfile(!editingProfile);
    if (editingProfile) await updateProfile();
  }

  function callBackProfile() {
    setPassRecovery(false);
  }

  async function sendSms(phoneState, auxCaptcha) {
    let auxPhone = '';
    auxPhone = auxPhone.replace(/\D/g, '');
    auxPhone = `+55${phoneState}`;
    firebaseApp.firebaseSendSMS(auxPhone, auxCaptcha).catch((errArg) => {
      const err = errArg.toString();
      let errorMsg = '';
      switch (err) {
        case 'Error: We have blocked all requests from this device due to unusual activity. Try again later.':
          errorMsg = t('ProfilePage-token-errorUnsualActivity-title');
          break;
        default:
          errorMsg = 'Erro Interno';
          break;
      }
      setDataAlert([errorMsg, t('ProfilePage-token-errorUnsualActivity-body')]);
      setShowAlert(true);
    });
  }

  async function testCode() {
    const userInput = token.toString().replace(/,/g, '');
    await firebaseApp
      .signInWithPhone(userInput)
      .then(async () => {
        setDataAlert(['Sucesso', 'Seu token foi verificado com sucesso!']);
        setShowPhoneVerication(false);
        setShowAlert(true);
        await finalizeEdition();
      })
      .catch(() => {
        logDebug(`Error ---  ${error}`);
        setShowIncorrectCodeModal(true);
        setDataAlert(['Código incorreto!', 'Deseja reenviar o SMS?']);
      });
  }

  function renderButtons(type) {
    if (type === 'editar') {
      return (
        <div className="profile-conta-area">
          <h1 className="profile-h1">{t('ProfilePage-contactInfo-title')}</h1>
          <Button
            size="large"
            variant="contained"
            color="custom"
            onClick={() => edit()}
          >
            {editingProfile
              ? t('ProfilePage-editButton-saveEdit')
              : t('ProfilePage-editButton-enableEdit')}
          </Button>
        </div>
      );
    }
    // if (type === 'esqueciSenha') {
    //   return (
    //     <div className="profile-centered-buttons">
    //       <Button
    //         color="custom"
    //         variant="outlined"
    //         onClick={() => setPassRecovery(true)}
    //       >
    //         {t('ProfilePage-changePasswordButton')}
    //       </Button>
    //     </div>
    //   );
    // }

    return <></>;
  }

  function renderItems(cathName, cathValue) {
    return (
      <div>
        <hr />
        <div className="profile-box">
          <p className="profile-title">{cathName}</p>
          <p className="profile-info">{cathValue}</p>
        </div>
      </div>
    );
  }

  function renderEditableItems(cathName, cathValue) {
    if (!editingProfile) {
      return (
        <div>
          <hr />
          <div className="profile-box">
            <p className="profile-title">
              {cathName}
              :
              {' '}
            </p>
            <p className="profile-info">{cathValue}</p>
          </div>
        </div>
      );
    }

    return (
      <div>
        <hr />
        <div className="profile-box">
          <p className="profile-title">
            {cathName}
            :
            {' '}
          </p>
          <input
            maxLength="50"
            value={cathValue}
            placeholder={cathValue}
            onChange={(event) => {
              if (cathName === t('ProfilePage-userProfile-phone')) setPhone(event.target.value);
              else if (cathName === t('ProfilePage-userProfile-email')) setEmail(event.target.value);
            }}
            className="profile-info-edit"
          />
        </div>
      </div>
    );
  }

  if (passRecovery) return <RecoverPass callbackFunc={() => callBackProfile()} />;

  if (refreshing || updating) {
    return (
      <div className="profilePage-loading">
        <Loading size="large" />
      </div>
    );
  }

  return (
    <div className="profilePage-container">
      {features.address && (
        <DialogUserAddress
          open={showModalAddress}
          setOpen={async (show) => {
            await getPatientAddress();
            setShowModalAddress(show);
          }}
        />
      )}
      <Row>
        <Col className="text-center">
          {error.visible && <p>{error.message}</p>}
          {tryAgainVisible && (
            <Button
              color="custom"
              variant="outlined"
              className="profile-password-button"
              onClick={() => onRefresh()}
            >
              {t('ProfilePage-tryAgainButton')}
            </Button>
          )}
        </Col>
      </Row>
      {!tryAgainVisible && (
        <Row>
          <Col>
            <div id="editPhone" />

            {renderItems(t('ProfilePage-userProfile-nameField'), name)}
            {socialName
              && renderItems(
                t('ProfilePage-userProfile-socialNameField'),
                socialName,
              )}
            {renderItems(t('ProfilePage-userProfile-birthdayField'), birthday)}
            {/* {identifier.map((item) => renderItems(checkRegisterType(item.value, item), item.value))} */}
            {identifier.map((item, index) => {
              const idType = checkRegisterType(item.value, item);
              return (
                <Fragment key={`identifier-${index}`}>
                  {idType !== 'Identificador' && renderItems(idType, item.value)}
                </Fragment>
              )
            })}
            {gender === 'M'
              && renderItems(
                t('ProfilePage-userProfile-genderField'),
                t('ProfilePage-userProfile-genderField-male'),
              )}
            {gender === 'F'
              && renderItems(
                t('ProfilePage-userProfile-genderField'),
                t('ProfilePage-userProfile-genderField-female'),
              )}
            {gender === 'O'
              && renderItems(
                t('ProfilePage-userProfile-genderField'),
                t('ProfilePage-userProfile-genderField-nonSpecified'),
              )}
            <br />
            {renderEditableItems(t('ProfilePage-userProfile-phone'), phone)}
            {renderEditableItems(t('ProfilePage-userProfile-email'), email)}
            <br />
            {renderButtons('esqueciSenha')}

            {showAlert && (
              <SimpleModal
                fullWidth={false}
                setModal={() => setShowAlert(false)}
                modal={modalBody}
              />
            )}
            {showPhoneVerication && (
              <SimpleModal
                fullWidth={false}
                setModal={() => setShowPhoneVerication(false)}
                modal={{
                  title: t('ProfilePage-tokenModal-title'),
                  body: (
                    <>
                      <p>
                        {t('ProfilePage-tokenModal-body')}
                        :
                      </p>
                      <input
                        maxLength="50"
                        type="number"
                        placeholder="Token"
                        id="tokenFirebase"
                        onChange={(event) => setToken(event.target.value)}
                      />
                    </>
                  ),
                  footer: (
                    <>
                      <Button
                        variant="contained"
                        color="custom"
                        onClick={() => testCode()}
                      >
                        Enviar
                      </Button>
                    </>
                  ),
                  visible: showPhoneVerication,
                  cancelButton: 'Cancelar',
                }}
              />
            )}
            {showIncorrectCodeModal && (
              <SimpleModal
                fullWidth={false}
                setModal={() => setShowIncorrectCodeModal(false)}
                noCancelButton
                modal={{
                  ...modalBody,
                  footer: (
                    <>
                      <Button
                        color="custom"
                        variant="contained"
                        onClick={() => {
                          setShowIncorrectCodeModal(false);
                          sendSms(phone, captcha);
                        }}
                      >
                        {t('ProfilePage-tokenModal-sendButton')}
                      </Button>
                      <Button
                        variant="contained"
                        color="default"
                        onClick={() => setShowIncorrectCodeModal(false)}
                      >
                        Fechar
                      </Button>
                    </>
                  ),
                  visible: showIncorrectCodeModal,
                }}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}
