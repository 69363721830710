import { addHours } from 'date-fns';
import MHDRequests from 'services/MHDRequests';
import { toast } from 'react-toastify';
import { oids } from 'configs';
import {
  capitalize_first_letters,
  logDebug,
  parse_reports,
  retrieveData,
  storeData,
} from './AuxiliarFunctions';
import moment from 'moment';

// import { oids } from './Constants';

export const getOrganizationData = (listOrg) => {
  if (!listOrg) return null;
  let result = listOrg.entry;
  if (!result) return null;
  result = result.map((entry) => {
    let firstCode = null;
    let firstDisplay = null;
    try {
      firstCode = entry.resource.type[0].coding[0].code;
      firstDisplay = entry.resource.type[0].coding[0].display;
    } catch (err) {
      firstCode = null;
      firstDisplay = null;
    }
    let orgDetails = {};
    try {
      orgDetails = {
        name: entry.resource?.alias[0],
        id: entry.resource.id?.split('-')[1],
        //   system: entry.resource.identifier[0].system.split('oid:')[1],
        //   value: entry[0].resource.identifier[0].value,
        status: entry.resource.active,
        //   legalName: entry.resource.name,
        //   typeVect: entry.resource.type,
        firstCode,
        firstDisplay,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      logDebug(
        'Erro em orgDetails dentro de'
          + 'getOrganizationData dentro de fhirParser',
      );
      orgDetails = {
        name: '',
        id: '',
        status: '',
        firstCode,
        firstDisplay,
      };
    }
    return orgDetails;
  });
  return result;
};

export const filtersHistoryData = (listHist, withoutSelf = true) => {
  if (!listHist) return null;
  try {
    if (withoutSelf) {
      let newList = [];
      newList = listHist.filter(
        (orign) => orign.sujeito.id !== orign.recurso[0].id,
      );
      return newList;
    }
    return listHist;
  } catch (err) {
    return null;
  }
};

export const getPeriodDateISO = (initDate, period) => {
  let dateNow = new Date(initDate);
  let monthBeforeNow = new Date();
  const month = dateNow.getMonth();
  monthBeforeNow = new Date(monthBeforeNow.setMonth(month - period));
  dateNow = dateNow.toISOString();
  monthBeforeNow = monthBeforeNow.toISOString();
  return [dateNow, monthBeforeNow];
};

function uniqByKeepLast(a, key) {
  return [...new Map(a.map((x) => [key(x), x])).values()];
}

const topicalizeTimeline = async (reports, types) => {
  const retTimeline = [
    reports,
    types.map((val) => {
      const filterType = (currentValue) => currentValue.doc_ref === val;
      const reportType = reports.filter(filterType);
      return [val, reportType];
    }),
  ];
  return retTimeline;
};

const orderReports = (reports) => {
  const data = [];
  for (const item of reports) {
    const year = item.serviceStart_time_date.split('/')[2];
    let year_found = false;
    for (const year_data of data) {
      if (year_data.title === year) {
        year_found = true;
        year_data.data.push(item);
      }
    }
    if (!year_found) data.push({ title: year, data: [item] });
  }
  for (const year of data) year.data = parse_reports(year.data);
  return data;
};

const constructDate = (date, type) => {
  let newDate = new Date(date);
  newDate = newDate.toISOString();

  if (date.includes('Z')) {
    newDate = addHours(new Date(date), -3);
    newDate = newDate.toISOString();
  }
  const condHours = newDate.split('T')[1];
  newDate = newDate.replace(/\D/g, '');
  switch (type) {
    //
    case '_time':
      break;
    //
    case '_time_date':
      newDate = `${newDate[6]}${newDate[7]}/${newDate[4]}${newDate[5]}/
                  ${newDate[0]}${newDate[1]}${newDate[2]}${newDate[3]}`;
      break;
    //
    case '_time_time':
      if (condHours) {
        newDate = `${newDate[8]}${newDate[9]}:
                  ${newDate[10]}${newDate[11]}`;
      } else newDate = null;

      break;
    default:
      break;
  }
  return newDate;
};

export const getEntriesTimeline = (timeline) => timeline.entry;

export const formatExamTimeline = async (entry, subjectToken) => {
  try {
    let formatedTimeline = [];
    let array = [];
    let testMap = new Map();
    if (entry) {
      for (let i = 0; i < entry.length; i++) {
        let obj = entry[i];
        if (obj.resourceType === "DiagnosticReport") {
          let baseId = obj.basedOn[0].reference.split("/")[1];
          let newTest = {};
          newTest.diag = obj;
          testMap.set(baseId, newTest);
        }
        else {
          let baseId = obj.id
          let newTest = testMap.get(baseId);
          if (newTest) {
            newTest.serv = obj;
            array.push(newTest);
            testMap.delete(baseId);
          }
        }
      }

      formatedTimeline = await array.map(async (val) => {
        const array = [];
        let name = null;
        let subject_name = null;
        let estabelecimento_saude = null;
        let subjectId_autor_oid = null;
        let subjectId_autor = null;
        let cns = null;
        let documento_clinico = '';
        let endereco_pdf = '';

        documento_clinico = val.diag.code.text;
        endereco_pdf = val.diag.presentedForm[0].url;
        subjectId_autor = "03184044602";
        subjectId_autor_oid = "2.16.840.1.113883.13.237";

        if (subjectId_autor) {
          const keyToRetrieveData = `patient-${subjectId_autor_oid}-${subjectId_autor}`;
          const patientJson = await retrieveData(keyToRetrieveData);
          if (patientJson) subject_name = JSON.parse(patientJson);
          else {
            subject_name = await MHDRequests.getPatientJson({ patientId: subjectId_autor, subjectToken });
            await storeData(keyToRetrieveData, JSON.stringify(subject_name));
          }

          if (typeof subject_name !== 'string' && subject_name !== null) {
            const splitting = subject_name.id.split('-');
            if (splitting[0] === oids.cns) cns = splitting[1];
            else cns = subject_name.identifier[0].value;

            subject_name = subject_name.name[0].text;
          }
          name = subject_name;
          if (name && name !== 'network_error' && name !== 'not_found') name = capitalize_first_letters(name.toLowerCase());
        }
        // let date = "2022-11-15T19:01:29";
        let date = val.diag.issued;
        val.diag.contained.forEach(e => {
          if(e.resourceType === "Organization") {
            estabelecimento_saude = e.name;
          }
        });
        const details = {
          cbo: 'falta o cbo',
          creation_time: constructDate(date, '_time'),
          creation_time_date: constructDate(date, '_time_date'),
          creation_time_time: constructDate(date, '_time_time'),
          doc_id: val.diag.presentedForm[0].url.split('/')[1],
          doc_ref: endereco_pdf,
          documento_clinico,
          estabelecimento_saude: estabelecimento_saude,
          registry_id: '`urn:uuid:${val.id}`',
          subjectId_autor,
          subjectId_autor_oid,
          subjectId_cns: cns,
          subject_name: val.serv.contained[0].name[0].text,
          returnDate: null,
        };
        if (date) {
          details.serviceStart_time = constructDate(
            date,
            '_time',
          );
          details.serviceStart_time_date = constructDate(
            date,
            '_time_date',
          );
          details.serviceStart_time_time = constructDate(
            date,
            '_time_time',
          );
        }
        // logDebug('details', details);
        array.push(details);
        return array;
      });
      // logDebug('formatedTimeline', formatedTimeline);
      formatedTimeline = await Promise.all(formatedTimeline);
      // logDebug('formatedTimeline1', formatedTimeline);
      const references = formatedTimeline.map((val) => val.registry_id);
      const ids = references;
      let reports = [];
      let types = [];
      for (const index in formatedTimeline) {
        if (formatedTimeline[index] !== 'network_error') {
          reports = reports.concat(formatedTimeline[index]);
          types.push(formatedTimeline[index][0].doc_ref);
          for (const obj of formatedTimeline[index]) ids.splice(ids.indexOf(obj.registry_id), 1);
        }
      }

      if (typeof reports === 'string') return reports;
      if (typeof reports[0] === 'string') return reports[0];

      reports.sort((a, b) => b.serviceStart_time - a.serviceStart_time);

      types = uniqByKeepLast(types, (it) => it);
      let topicalized = await topicalizeTimeline(reports, types);
      const reports_group = orderReports(reports);
      topicalized = topicalized[1].map((topic) => {
        const topicalized_group = orderReports(topic[1]);
        return [topic[0], topicalized_group];
      });
      return [reports_group, topicalized];
    }
  } catch (err) {
    logDebug('formatExamTimeline: err', err);
  }
  return null;
};

export const formatSurveillanceTimeline = async (entry) => {
  try {
    const reports = await Promise.all(entry.map(async (val) => {
      const composition = val.resource.entry.find((e) => e.fullUrl.includes('composition-1')).resource;
      const patient = val.resource.entry.find((e) => e.fullUrl.includes('patient-1'))?.resource;
      const condition = val.resource.entry.find((e) => e.fullUrl.includes('condition-1'))?.resource;
      const location = val.resource.entry.find((e) => e.fullUrl.includes('location-1'))?.resource;
      const obs1 = val.resource.entry.find((e) => e.fullUrl.includes('observation-1'))?.resource;
      const obs2 = val.resource.entry.find((e) => e.fullUrl.includes('observation-2'))?.resource;
      const obs3 = val.resource.entry.find((e) => e.fullUrl.includes('observation-3'))?.resource;
      
      let obs_components = [];
      if (obs1?.component) obs_components = [...obs_components, ...obs1.component];
      if (obs2?.component) obs_components = [...obs_components, ...obs2.component];
      if (obs3?.component) obs_components = [...obs_components, ...obs3.component];

      const date = composition.date;

      let agravo_doenca = null;
      if (condition?.code.text) agravo_doenca = condition.code.text;
      else if (condition?.code?.coding?.[0]?.code && condition?.code?.coding?.[0]?.display) agravo_doenca = `${condition?.code?.coding?.[0]?.code} - ${condition.code.coding[0].display}`;

      const caracterizacao_notificacao = {
        id: val.resource.id.split('-')[1],
        tipo_notificacao: composition.category[0].coding[0].display || "Notificação Individual",
        data_notificacao: moment(date).format('DD/MM/YYYY'),
        agravo_doenca,
        data_primeiros_sintomas: condition?.onSetDateTime || null,
        unidade_de_saude: `${composition.author[0].reference.split('-')?.[1]} - ${composition.author[0].display}`
      };

      const info_clinicas = {
        gestante: obs_components.find((c) => c.valueCodeableConcept?.coding?.[0]?.system?.includes('idade-gestacional'))?.valueCodeableConcept.coding[0].display || null
      };

      const endereco_residencia = {
        pais: patient?.address[0].country || null,
        cep: patient?.address[0].postalCode || null,
        uf: patient?.address[0].state || null,
        cidade: patient?.address[0].city || null,
        distrito: patient?.address[0].district || null,
        zona: patient?.address?.[0]?.extension?.[0]?.extension?.find((extension) => extension.url === 'zone')?.valueCodeableConcept.coding[0].display,
        lat: patient?.address?.[0]?.extension?.[0]?.extension?.find((extension) => extension.url === 'latitude')?.valueDecimal || null,
        lng: patient?.address?.[0]?.extension?.[0]?.extension?.find((extension) => extension.url === 'longitude')?.valueDecimal || null
      };

      const data_investigacao_code = '77979-3';
      const data_obito_code = '81954-0';
      const evolucao_caso_code = '11506-3';
      const data_encerramento_code = 'dataEncerramento';
      const criterio_confirmacao_descarte_code = 'criterioConfirmacaoDescarte';
      const doenca_relacionada_trabalho_code = 'doencaRelacionadaTrabalho';

      const autoctone = obs_components.find((c) => c.valueCodeableConcept?.coding?.[0]?.system?.includes('caso-autoctone'))?.valueCodeableConcept.coding[0].display || null;
      const data_investigacao = obs_components.find((c) => c.code.coding[0].code === data_investigacao_code)?.valueDateTime || null;
      const data_obito = obs_components.find((c) => c.code.coding[0].code === data_obito_code)?.valueDateTime || null;
      const data_encerramento = obs_components.find((c) => c.code.coding[0].code === data_encerramento_code)?.valueDateTime || null;

      const conclusao = {
        classificacao_final: condition?.verificationStatus?.text || null,
        criterio_confirmacao_descarte: obs_components.find((c) => c.code.coding[0].code === criterio_confirmacao_descarte_code)?.valueCodeableConcept.coding[0].display || null,
        evolucao_caso: obs_components.find((c) => c.code.coding[0].code === evolucao_caso_code)?.code.coding[0].display || null,
        doenca_relacionada: obs_components.find((c) => c.code.coding[0].code === doenca_relacionada_trabalho_code)?.valueCodeableConcept.coding[0].display || null,
        data_investigacao: data_investigacao ? moment(data_investigacao).format('DD/MM/YYYY') : null,
        data_obito: data_obito ? moment(data_obito).format('DD/MM/YYYY') : null,
        data_encerramento: data_encerramento ? moment(data_encerramento).format('DD/MM/YYYY') : null,
        autoctone   
      };

      const fonte_infeccao = autoctone === 'sim' ? endereco_residencia : {
        pais: location?.address.country || null,
        cep: location?.address.postalCode || null,
        uf: location?.address.state || null,
        cidade: location?.address.city || null,
        distrito: location?.address.district || null
      };

      return {
        serviceStart_time: constructDate(date, '_time'),
        serviceStart_time_date: constructDate(date,'_time_date'),
        serviceStart_time_time: constructDate(date, '_time_time'),
        caracterizacao_notificacao,
        info_clinicas,
        endereco_residencia,
        conclusao,
        fonte_infeccao
      };
    }));

    reports.sort((a, b) => b.serviceStart_time - a.serviceStart_time);

    return orderReports(reports);
  } catch (err) {
    logDebug('formatTimeline: err', err);
  }
  return [];
};

export const formatTimeline = async (entry, subjectToken) => {
  try {
    let formatedTimeline = [];
    if (entry) {
      formatedTimeline = await entry.map(async (val) => {
        const array = [];
        let name = null;
        let subject_name = null;
        let estabelecimento_saude = null;
        let subjectId_autor_oid = null;
        let subjectId_autor = null;
        let cns = null;
        let documento_clinico = '';
        if (val.content[0].attachment?.contentType === 'text/xml') {
          documento_clinico = val.type.coding[0].code.split('v4')[0];
          name = val.contained.reduce((vetor, atual) => {
            if (atual.resourceType === 'Practitioner') {
              subjectId_autor_oid = atual.identifier[0].system.split('oid:')[1];
              subjectId_autor = atual.identifier[0].value;
              // logDebug('ponto 1');
              if (atual.name) return atual.name[0].text;
            }
            // logDebug('ponto 2');
            return vetor;
          }, '');
          estabelecimento_saude = val.contained.reduce((vetor, atual) => {
            // logDebug('ponto 3');
            if (atual.resourceType === 'Organization') return atual.name;
            // logDebug('ponto 4');
            return vetor;
          }, '') || null;
        } else if (
          val.content[0].attachment?.contentType === 'application/pdf'
        ) {
          documento_clinico = val.type.coding[0].display;
          name = 'NOME';
          estabelecimento_saude = '';

          // eslint-disable-next-line array-callback-return
          val.author.map((item) => {
            if (item.reference.includes('Practitioner/')) {
              const identifier = item.reference.split('/')[1];
              subjectId_autor = identifier.split('-')[1];
              subjectId_autor_oid = identifier.split('-')[0];
            }
          });
        }

        if (subjectId_autor) {
          const keyToRetrieveData = `patient-${subjectId_autor_oid}-${subjectId_autor}`;
          const patientJson = await retrieveData(keyToRetrieveData);
          if (patientJson) subject_name = JSON.parse(patientJson);
          else {
            subject_name = await MHDRequests.getPatientJson({ patientId: subjectId_autor, subjectToken });
            await storeData(keyToRetrieveData, JSON.stringify(subject_name));
          }

          if (typeof subject_name !== 'string' && subject_name !== null) {
            const splitting = subject_name.id.split('-');
            if (splitting[0] === oids.cns) cns = splitting[1];
            else cns = subject_name.identifier[0].value;

            subject_name = subject_name.name[0].text;
          }
          name = subject_name;
          if (name && name !== 'network_error' && name !== 'not_found') name = capitalize_first_letters(name.toLowerCase());
        }
        const details = {
          cbo: val?.context?.practiceSetting?.coding[0].display,
          creation_time: constructDate(val.date, '_time'),
          creation_time_date: constructDate(val.date, '_time_date'),
          creation_time_time: constructDate(val.date, '_time_time'),
          doc_id: val.content[0].attachment.url.split('/')[1],
          doc_ref: val.type.coding[0].code,
          documento_clinico,
          estabelecimento_saude,
          registry_id: `urn:uuid:${val.id}`,
          subjectId_autor,
          subjectId_autor_oid,
          subjectId_cns: cns,
          subject_name: name,
          returnDate: val?.resource?.extension
            ? val.resource.extension[0].valueDateTime
            : null,
        };
        if (val.context.period.start) {
          details.serviceStart_time = constructDate(
            val.context.period.start,
            '_time',
          );
          details.serviceStart_time_date = constructDate(
            val.context.period.start,
            '_time_date',
          );
          details.serviceStart_time_time = constructDate(
            val.context.period.start,
            '_time_time',
          );
        }
        // logDebug('details', details);
        array.push(details);
        return array;
      });
      // logDebug('formatedTimeline', formatedTimeline);
      formatedTimeline = await Promise.all(formatedTimeline);
      // logDebug('formatedTimeline1', formatedTimeline);
      const references = formatedTimeline.map((val) => val.registry_id);
      const ids = references;
      let reports = [];
      let types = [];
      for (const index in formatedTimeline) {
        if (formatedTimeline[index] !== 'network_error') {
          reports = reports.concat(formatedTimeline[index]);
          types.push(formatedTimeline[index][0].doc_ref);
          for (const obj of formatedTimeline[index]) ids.splice(ids.indexOf(obj.registry_id), 1);
        }
      }

      if (typeof reports === 'string') return reports;
      if (typeof reports[0] === 'string') return reports[0];

      reports.sort((a, b) => b.serviceStart_time - a.serviceStart_time);

      types = uniqByKeepLast(types, (it) => it);
      let topicalized = await topicalizeTimeline(reports, types);
      const reports_group = orderReports(reports);
      topicalized = topicalized[1].map((topic) => {
        const topicalized_group = orderReports(topic[1]);
        return [topic[0], topicalized_group];
      });
      return [reports_group, topicalized];
    }
  } catch (err) {
    logDebug('formatTimeline: err', err);
  }
  return null;
};

export const conditionData = () =>
// listCond
{
  // let result = listOrg.entry;
  // result = result.map((entry) => {
  // });
};

export const slotChangeStatus = (item, status) => {
  const checkEmptyObj = Object.keys(item).length === 0 && item.constructor === Object;
  if (!item || checkEmptyObj) return null;
  try {
    const newItem = item;
    item.resource.status = status;
    return newItem;
  } catch (err) {
    logDebug('Error in slotChangeStatus', err);
    return null;
  }
};

export const filtersOutResourceWithEmptyCode = (resource) => {
  const newResourceArray = resource?.entry?.filter(
    (ent) => !!ent?.resource?.code,
  );
  return newResourceArray;
};

export const getRolesFromPractitionerRoles = (practitionerRoles) => {
  try {
    let roles = [];
    roles = practitionerRoles?.map((ent) => {
      if (ent?.resource?.active) {
        return {
          id: ent?.resource?.id.split('-')[0],
          code: ent?.resource?.code?.map((cod) => cod.coding[0]),
        };
      }
      return null;
    });

    return roles.filter((v) => v);
  } catch (err) {
    return null;
  }
};

export const filtersOutNonAdminRoles = (rols) => {
  try {
    let roles = [];
    roles = rols?.map((ent) => ({
      id: ent?.id.split('-')[0],
      code: ent?.code?.filter(
        (cod) => cod?.code.substring(0, 11) === 'ROLE_Gestor',
      ),
    }));
    return roles;
  } catch (err) {
    return null;
  }
};

export const getResourceOidIDFromIdentifier = (identifier) => {
  const splitting = identifier.split('-');
  const oid = splitting[0];
  const id = splitting[1];
  return {
    oid,
    id,
  };
};

export function checkResponseFhir(response) {
  if (typeof response === 'string') throw Error(response);
}

export function getNextLink(links) {
  try {
    let nextLink = links.filter((link) => !!link.relation.includes('next'));
    nextLink = nextLink[0].url;
    return nextLink;
  } catch (err) {
    return null;
  }
}

export function checkCanVaccinateOrCheckIn(roles) {
  let canVaccinate = false;
  let canCheckIn = false;
  // eslint-disable-next-line array-callback-return
  roles.map((inner) => {
    if (inner.code === 'ROLE_ProfissionalVacinacao') canVaccinate = true;
    if (inner.code === 'ROLE_Acolhimento') canCheckIn = true;
  });
  return {
    canVaccinate,
    canCheckIn,
  };
}

export const checkBundleError = (responseEntry) => {
  const status = (item) => parseInt(item.response.status.split(' ')[0], 10);
  const success = responseEntry.filter(
    (item) => status(item) >= 200 && status(item) < 300,
  );
  if (success.length === responseEntry.length) return 'success';
  return 'error';
};

export function fhirErrorToasts(errorMessage) {
  let toastDisplayMessage;
  let toastClosing;

  switch (errorMessage) {
    // 500
    case 'internal_error':
      toastDisplayMessage = 'Ops! Tivemos um problema resolvendo a sua requisição.\n Por favor, entre em contato com a equipe de manutenção.';
      toastClosing = 10000;
      break;
    // 504
    case 'timeout':
      toastDisplayMessage = 'Ops! Parece que o sistema está demorando para responder. Por favor, tente novamente em alguns minutos.\n Se o problema persistir entre em contato com a equipe de manutenção.';
      toastClosing = 10000;
      break;
    // 400
    case 'bad_request':
      toastDisplayMessage = 'Ops! Não conseguimos entender a sua requisição.\n Por favor, entre em contato com a equipe de manutenção.';
      toastClosing = 10000;
      break;
    // 401
    case 'unauthorized':
      toastDisplayMessage = 'Ops! Você deve estar autenticado para fazer essa requisição. Por favor, entre com suas credenciais.\n Se o problema persistir entre em contato com a equipe de manutenção.';
      toastClosing = 10000;
      break;
    // 403
    case 'forbidden':
      toastDisplayMessage = 'Ops! Parece que sua autenticação não é válida para esta requisição. Cheque suas credenciais e teste entrar novamente no portal.\n Se o problema persistir entre em contato com a equipe de manutenção.';
      toastClosing = 10000;
      break;
    // 404
    case 'not_found':
      toastDisplayMessage = 'Ops! Parece que você está tentando acessar um recurso que não existe em nosso sistema.\n Se tem certeza que este recurso existe, por favor, entre em contato com a equipe de manutenção.';
      toastClosing = 10000;
      break;
    // 409
    case 'conflict':
      toastDisplayMessage = 'Ops! Parece que a requisição feita está causando um conflito interno.\n Por favor, entre em contato com a equipe de manutenção.';
      toastClosing = 10000;
      break;
    // Genericos de rede. No momento sendo chamados em qualquer erro que não seja algum dos mapeados acima.
    case 'network_error':
      toastDisplayMessage = 'Ops! Parece que estamos tendo problemas de conexão. Por favor, cheque sua conexão de internet e tente novamente em alguns minutos.\n Se o problema persistir entre em contato com a equipe de manutenção.';
      toastClosing = 10000;
      break;
    default:
      toastClosing = 10000;
  }

  toast.error(toastDisplayMessage, {
    position: 'top-center',
    autoClose: toastClosing,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  // console.warn(errorMessage);
}
