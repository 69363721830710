import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { languagesList } from 'theme/settings';

import { TextField, MenuItem } from '@material-ui/core';
import i18n from 'i18n';

import './DropDownTranslate.css';

import { useTranslation } from 'react-i18next';

export function DropDownTranslate({ showTitle }) {

  const { t } = useTranslation('DropDownTranslate', { i18n });

  function changeLang(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <>
      <div className="dropdowntranslate">
        {showTitle ? <span className="dropdowntranslate-title">{t('DropDownTranslate-idiom')}</span> : <></>}

        <TextField
          select
          value={i18n.language}
          variant="outlined"
          onChange={(event) => changeLang(event.target.value)}
          // helperText="Selecione sua região"
        >
          {languagesList.map((lang) => (
            <MenuItem key={`key-${lang}`} value={lang}>
              {lang}
            </MenuItem>
          ))}
        </TextField>
      </div>
     </>
  );
}

DropDownTranslate.defaultProps = {
  showTitle: false
}

DropDownTranslate.propTypes = {
  showTitle: PropTypes.bool
}