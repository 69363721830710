import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core';
import { Button } from 'components';

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const StyledDialog = withStyles({
  root: {
    zIndex: '1400 !important', // Drawer do visualizer
  },
})(Dialog);

export default function ReportModal({show, setShow, title, contextText, body}) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = () => {
    setOpen(false);
    setShow(false);
  };

  return (
    <StyledDialog
      fullWidth
      scroll="body"
      maxWidth="md"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {contextText}
        </DialogContentText>
        {body}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="custom">
          Fechar
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
