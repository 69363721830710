import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Tooltip, TextField,
} from '@material-ui/core';
import { formatDateDisplay } from 'utils/dateFunctions';
import { translate } from 'utils/AuxiliarFunctions';
import { Button } from 'components';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

function ProceduresTabContent({ documents, selectReport }) {
  const { t } = useTranslation('PatientSummary');
  const [showNotes, setShowNotes] = useState({});
  const headers = [t('myRegisters-patientSummary-healthSummary-tab-column-code'), t('myRegisters-patientSummary-healthSummary-tab-column-description'), t('myRegisters-patientSummary-healthSummary-tab-column-notes'), t('myRegisters-patientSummary-healthSummary-tab-column-date')];
  const rows = documents.map((document) => {
    const procedure = document.code.coding[0];
    let notes = '';
    if (Array.isArray(document.note)) {
      notes = document.note.map(
        (item) => item.text,
      ).toString();
    }
    return ({
      id: document.id,
      description: procedure.display,
      notes,
      status: translate(document?.status),
      source: document.meta.tag ? document.meta.tag[0].code : document.meta.source,
      date: formatDateDisplay(document.performedDateTime),
    });
  });

  return (
    <>
      <div
        style={{ width: '100%', padding: '1% 3% 1% 3%' }}
      >
        <Autocomplete
          fullWidth
          clearOnEscape
          clearOnBlur
          autoComplete
          onChange={(_, value) => {
            if (value) selectReport(value.source);
          }}
          options={rows}
          autoHighlight
          getOptionLabel={
            (option) => `${option.description} - ${option.date} (${option.status})`
          }
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              label="Pesquisar Procedimento"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
      </div>
      <Table
        size="small"
        aria-label="enhanced table"
      >
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell
                key={i}
              >
                <h5>
                  {header}
                </h5>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.map((document) => (
              <Fragment key={document.id}>
                <Tooltip
                  placement="top"
                  arrow
                  title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
                >
                  <TableRow
                    hover
                  >
                    <TableCell
                      onClick={() => selectReport(document.source)}
                      padding="checkbox"
                    >
                      {document.status}
                    </TableCell>
                    <TableCell
                      onClick={() => selectReport(document.source)}
                    >
                      {document.description}
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                    >
                      <Button
                        variant="outlined"
                        disabled={document.notes === ''}
                        onClick={() => {
                          const newShowNotes = { ...showNotes };
                          const actualNote = showNotes[document.id];
                          newShowNotes[document.id] = !actualNote;
                          setShowNotes(newShowNotes);
                        }}
                      >
                        { showNotes[document.id] ? t('myRegisters-patientSummary-healthSummary-tab-procedures-close') : t('myRegisters-patientSummary-healthSummary-tab-procedures-open')}
                      </Button>
                    </TableCell>
                    <TableCell
                      onClick={() => selectReport(document.source)}
                      padding="checkbox"
                    >
                      {document.date}
                    </TableCell>
                  </TableRow>
                </Tooltip>
                {
                  !!showNotes[document.id]
                  && (
                    <TableRow>
                      <TableCell>
                        <strong>
                          Notas:
                        </strong>
                      </TableCell>
                      <TableCell>
                        {document.notes}
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  )
                }
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </>
  );
}

ProceduresTabContent.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectReport: PropTypes.func.isRequired,
};

export { ProceduresTabContent };
