import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { FaUserAlt, FaKey } from 'react-icons/fa';
import { Controller, useForm } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  storeData,
  retrieveData,
  logoutFromPortal,
  clearAllData,
  removeData,
  verifyCPF,
} from 'utils/AuxiliarFunctions';
import loginSchema from 'validators/loginSchema';
import { testCredentials, prodMode, features } from 'configs';
import { Loading, Button } from 'components';
import xdsToken from 'services/xdsToken';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import './LoginForm.css';
import { useUserContext } from 'store/UserContext';

import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

export function LoginForm({ setCpfValid, cpfValid }) {
  const history = useHistory();

  const [username, setUsername] = useState(
    features.testCredentials ? testCredentials.username : '',
  );
  const [password, setPassword] = useState(
    features.testCredentials ? testCredentials.password : '',
  );
  const [loading, setLoading] = useState();
  const userContext = useUserContext();
  const { t } = useTranslation('LoginPage', { i18n });

  const {
    register, errors, control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(loginSchema(t)),
  });

  function handleInvalidUserOrPass() {
    toast.error('Usuário ou senha inválidos.');
  }

  function handleInvalidCPF() {
    toast.error('CPF Inválido, digite novamente.');
  }

  useEffect(() => {
    const setLogin = async () => {
      await clearAllData();
      const resetUser = userContext[2];
      await resetUser();
      const setUser = userContext[1];
      await setUser({});
      const user = await retrieveData('username');
      if (user) {
        const userPassword = await retrieveData('password');
        setUsername(user);
        setPassword(userPassword);
      } else logoutFromPortal();
    };
    setLogin();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function redirectToHome() {
    await removeData('purposeOfUse');
    await removeData('cnesToUse');
    await storeData('patientId', '');
    history.push('/');
  }

  if (loading) {
    return (
      <div className="loading-scrn">
        <Loading size="large" />
      </div>
    );
  }

  async function login() {
    const valid = isCpfValid();
    if (valid) await regularLogin(username, password);
    else handleInvalidCPF();
  }

  async function regularLogin(cpfArg, passwordArg) {
    await storeData('govBR', false);
    setLoading(true);
    const cpf = cpfArg.replace(/\D/g, '');
    await storeData('username', cpf);

    const loginReponse = await xdsToken.grantPasswordLogin(
      cpf,
      passwordArg,
      true,
    );
    const token_expiration = new Date();
    token_expiration.setSeconds(
      token_expiration.getSeconds() + loginReponse.expires_in,
    );

    await storeData('application_token', loginReponse.access_token);
    await storeData('token', loginReponse.access_token);
    await storeData('refresh_token', loginReponse.refresh_token);
    await storeData('Token_exp', token_expiration);

    const condToken = (loginReponse.access_token
    && loginReponse.access_token !== 'undefined');

    if (condToken) {
      await storeData('userLogged', true);
      setLoading(false);
      await redirectToHome();
    } else {
      setLoading(false);
      handleInvalidUserOrPass();
      setCpfValid(false);
    }
  }

  function isCpfValid() {
    const condCPFValid = verifyCPF(username);
    if (condCPFValid) {
      setCpfValid(true);
      return true;
    }
    setCpfValid(false);
    return false;
  }

  const renderForm = () => (
    <>
      <div className="Login_formContainerInp">
        <div className="Login_icons">
          <FaUserAlt />
        </div>
        <div className="Login_inputContainerData">
          <Controller
            name="user"
            control={control}
            defaultValue=""
            className="Login_inputContainer"
            render={({ onChange }) => (
              <TextField
                variant="outlined"
                size="medium"
                fullWidth
                label={t('loginForm-formUser-labelUser')}
                placeholder={t('loginForm-formUser-placeholdUser')}
                ref={register}
                type="text"
                value={username}
                onChange={(e) => {
                  e.preventDefault();
                  onChange(e.target.value);
                  setUsername(e.target.value);
                }}
                error={!!errors?.['user']}
                helperText={errors?.['user']?.message}
              />
            )}
          />
        </div>
      </div>
      <div className="Login_formContainerInp">
        <div className="Login_icons">
          <FaKey />
        </div>
        <div className="Login_inputContainerData">
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ onChange }) => (
              <TextField
                variant="outlined"
                size="medium"
                fullWidth
                label={t('loginForm-formUser-labelPassword')}
                placeholder={t('loginForm-formUser-placeholdPassword')}
                ref={register}
                value={password}
                type="password"
                onChange={(e) => {
                  e.preventDefault();
                  onChange(e.target.value);
                  setPassword(e.target.value);
                }}
                error={!!errors?.['password']}
                helperText={errors?.['password']?.message}
              />
            )}
          />
        </div>
      </div>
    </>
  );

  return (
    <form>
      {renderForm()}
      {!cpfValid && (
        <div className="Login_formContainer">
          <Button
            onClick={login}
            variant="contained"
            color="custom"
            className="Login_Button"
            type="submit"
          >
            {t('loginForm-buttomLogin-buttomLogInPortal')}
          </Button>
        </div>
      )}
    </form>
  );
}

LoginForm.propTypes = {
  setCpfValid: PropTypes.func.isRequired,
  cpfValid: PropTypes.bool.isRequired,
};
