import React, { Component } from "react";
import "./Tab.css";
class Tab extends Component {

  constructor(props) {
    super(props);
  }
  
  state = {
    selected: this.props.selected || 0
  };

  handleChange(index) {
    this.setState({ selected: index });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.handleChange(this.props.selected || 0)
    }
  }
  

  render() {
    return (
      <>
        <ul>
          {this.props.children.map((elem, index) => {
            let style = index === this.state.selected ? "selected" : "";
            return (
              <li
                key={index}
                className={style}
                onClick={() => this.handleChange(index)}
              >
                {elem.props.title}
              </li>
            );
          })}
        </ul><div className={`tab${this.props.className ? ` ${this.props.className}` : ''}`}>{this.props.children[this.state.selected]}</div>
      </>
    );
  }
}

export { Tab };