/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */

import base64 from 'base-64';
import utf8 from 'utf8';
import uuid from 'uuid';
import { endpoints, oids } from 'configs';
import {
  retrieveData,
  removeXMLInvalidChars,
  fetchWithTimeout,
  verifyUnauthorized,
  logDebug,
  verifyCPF,
  CNSisValid,
} from 'utils/AuxiliarFunctions';
// import { oids } from 'utils/Constants';
import {
  registroImunoBiologico,
  bundleVaccine,
} from './vaccination/requestTemplates';
// import { getEntriesTimeline } from 'utils/fhirParser';
import xdsToken from './xdsToken';

const {
  FHIR,
  MHDVaccination,
  principalDomain,
  ADMFHIR,
  TERMINOLOGY,
  USER_FHIR,
} = endpoints;

class fhirRequests {
  static catchError = (err) => {
    switch (parseInt(err.message, 10)) {
      case 500:
        return 'internal_error';
      case 504:
        return 'timeout';
      case 400:
        return 'bad_request';
      case 401:
        return 'unauthorized';
      case 403:
        return 'forbidden';
      case 404:
        return 'not_found';
      case 409:
        return 'conflict';
      default:
        return 'network_error';
    }
  };

  static async writeBundleVaccination({
    bundleInfo,
    mode,
    recoveredDocumentReferenceId,
  }) {
    const {
      patientIDOID,
      patientID,
      practitionerCns,
      practitionerName,
      cnes,
      cnesName,
      systemId,
      manufacturer,
      vaccine,
      dosage,
      lote,
      cnpjManufacturer,
      grupoAtendimento,
      returnDate,
      creationDate,
    } = bundleInfo;

    const timeMili = Math.floor(Math.random() * 1000000) + 1;
    const timestamp = new Date().toISOString();
    const documentManifestUUID = uuid.v4();
    const documentReferenceUUID = uuid.v4();

    let xml = registroImunoBiologico({
      cnes,
      practitionerName,
      practitionerCns,
      timestamp,
      manufacturer,
      vaccine,
      dosage,
      grupoAtendimento,
      CBOCode: '',
      cnpjManufacturer,
      lote,
      creationDate,
    });
    xml = utf8.encode(xml);

    let RI1 = base64.encode(xml);
    RI1 = btoa(xml);
    let body = bundleVaccine(
      patientIDOID,
      patientID,
      timestamp,
      practitionerCns,
      documentManifestUUID,
      documentReferenceUUID,
      practitionerName,
      cnes,
      cnesName,
      systemId,
      timeMili,
      RI1,
      returnDate,
      creationDate,
      mode,
      recoveredDocumentReferenceId,
    );
    body = utf8.encode(body);
    body = removeXMLInvalidChars(body);
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const url = `https://${principalDomain}:8243/${MHDVaccination}/R4`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+xml;');
    myHeaders.append('Accept', 'application/fhir+xml');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body,
    };

    const responseBundle = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 202) throw new Error(response.status);
        return response;
      })
      .catch((err) => fhirRequests.catchError(err, 'writeBundleVaccination'));

    return responseBundle;
  }

  /**
   * Função que faz vários GET's através de um bundle
   * @param {*} urls -> Array com todas as urls
   */
  static async getResourcesWithUrlsBundle({ urls }) {
    const entries = urls?.map((item) => ({
      request: {
        method: 'GET',
        url: item,
      },
    }));
    const resp = await fhirRequests.postBundle(entries);
    return resp;
  }

  static async getResources({ type, query, nextPage }) {
    // params = lista de parâmetros no formato: param1=100&param2=200
    // await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    // const applicationToken = await retrieveData('application_token');
    // const applicationToken = await retrieveData('application_token');
    const applicationToken = await xdsToken.getApplicationToken();
    let url = `${ADMFHIR}/${type}?${query}`;
    if (nextPage) url = `${ADMFHIR}/${nextPage}`;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const resourceJSON = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getLocation'));

    if (typeof resourceJSON === 'string') return resourceJSON;
    return resourceJSON;
  }

  static async getResource({ id, type }) {
    // params = lista de parâmetros no formato: param1=100&param2=200
    // await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    // const applicationToken = await retrieveData('application_token');
    // const applicationToken = await retrieveData('application_token');
    const applicationToken = await xdsToken.getApplicationToken();

    const url = `${ADMFHIR}/${type}/${id}`;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const resourceJSON = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getLocation'));

    if (typeof resourceJSON === 'string') return resourceJSON;
    return resourceJSON;
  }

  static async putResource({
    id, type, objectString, query,
  }) {
    // params = lista de parâmetros no formato: param1=100&param2=200
    // await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    // const applicationToken = await retrieveData('application_token');
    // const applicationToken = await retrieveData('application_token');
    const applicationToken = await xdsToken.getApplicationToken();

    let url = `${ADMFHIR}/${type}/${id}`;
    if (!id) url = `${ADMFHIR}/${type}`;
    if (query) url = `${url}?${query}`;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: objectString,
    };

    const resourceJSON = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getLocation'));

    if (typeof resourceJSON === 'string') return resourceJSON;
    return resourceJSON;
  }

  static async deleteResource({ id, type }) {
    // params = lista de parâmetros no formato: param1=100&param2=200
    // await xdsToken.getBearerToken();
    // const token = await retrieveData('token');
    const token = await retrieveData('token');
    // const applicationToken = await retrieveData('application_token');
    // const applicationToken = await retrieveData('application_token');
    const applicationToken = await xdsToken.getApplicationToken();

    const url = `${FHIR}/${type}/${id}`;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    };

    const resourceJSON = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getLocation'));

    if (typeof resourceJSON === 'string') return resourceJSON;
    return resourceJSON;
  }

  static async getListOrganization(city = null, name = null) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    let url = `${FHIR}/R4/Organization`;
    // if (city) url = `${FHIR}/Organization?address-city=${city}`;
    if (city || name) url = `${url}?`;
    if (city) url = `${url}address-city=${city}`;
    if (name && city) url = `${url}&name=${name}`;
    if (name && !city) url = `${url}name=${name}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const organizationJSON = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getListOrganization'));

    if (typeof organizationJSON === 'string') return organizationJSON;
    return organizationJSON;
  }

  static async getTerminology(type, filter) {
    await xdsToken.getBearerToken();
    const myHeaders = new Headers();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const purposeOfUse = await retrieveData('purposeOfUse');
    // const token = '4145ede0-862f-33b2-8e3d-78b06e9f3c95';
    // myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    let url = `${TERMINOLOGY}/ValueSet/${type}/$expand`;

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    if (filter) url = `${url}?filter=${filter}`;

    const resp = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getTerminology'));

    if (typeof resp !== 'string') return resp.expansion.contains;

    return resp;
  }

  static async getOrganizationList({
    cityId = null,
    postalCode = null,
    countParams = null,
    name = null,
    types = null,
    organization = null,
  }) {
    // params = lista de parâmetros no formato: param1=100&param2=200
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    let url = `${ADMFHIR}/Organization?_format=json&_pretty=true&_sort=name&_count=100`;
    if (cityId) url = `${url}&address-city=${cityId}`;
    if (postalCode) url = `${url}&address-postalcode=${postalCode}`;
    if (name) url = `${url}&name=${name}`;
    if (organization) url = `${url}&identifier=${organization}`;
    if (countParams) url = `${url}&${countParams}`;
    if (types) url = `${url}&${types}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const organizationJSON = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getOrganizationList'));

    if (typeof organizationJSON === 'string') return organizationJSON;
    return organizationJSON;
  }

  static async getLocationList({ organization = null, countParams = null }) {
    // params = lista de parâmetros no formato: param1=100&param2=200
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    let url = `${FHIR}/Location?_format=json&_pretty=true&_sort=name`;

    if (organization) url = `${url}&organization=${oids.organization}-${organization}`;
    if (countParams) url = `${url}&${countParams}`;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const organizationJSON = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getLocationList'));

    if (typeof organizationJSON === 'string') return organizationJSON;
    return organizationJSON;
  }

  static async getLocation({ id }) {
    // params = lista de parâmetros no formato: param1=100&param2=200
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${FHIR}/Location/${id}`;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const organizationJSON = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getLocation'));

    if (typeof organizationJSON === 'string') return organizationJSON;
    return organizationJSON;
  }

  static async deleteLocation({ id }) {
    // params = lista de parâmetros no formato: param1=100&param2=200
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${FHIR}/Location/${id}`;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    };

    const organizationJSON = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getLocation'));

    if (typeof organizationJSON === 'string') return organizationJSON;
    return organizationJSON;
  }

  static async getOrganization(organization) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    // const organizationOid = '${oids.organization}';
    const url = `${FHIR}/Organization?identifier=${organization}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const organizationJSON = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getOrganization'));

    verifyUnauthorized(organizationJSON);

    if (typeof organizationJSON === 'string') return organizationJSON;
    return organizationJSON;
  }

  static async getAppointments({
    location = null,
    countParams = null,
    patientId,
    start,
    end,
  }) {
    // params = lista de parâmetros no formato: param1=100&param2=200
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    let url = `${ADMFHIR}/Appointment?_format=json&_pretty=true`;

    if (location) url = `${url}&location=${location}`;
    if (patientId) {
      const oid = verifyCPF(patientId) ? oids.cpf : oids.cns;
      url = `${url}&patient=Patient/${oid}-${patientId}`;
    }
    if (start) url = `${url}&date=ge${start}`;
    if (end) url = `${url}&date=le${end}`;
    if (countParams) url = `${url}&${countParams}`;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const appointmentsJson = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getAppointments'));

    if (typeof appointmentsJson === 'string') return appointmentsJson;
    return appointmentsJson;
  }

  static async getSupplyDeliveryList({ id = null }) {
    // params = lista de parâmetros no formato: param1=100&param2=200
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    let url = `${FHIR}/SupplyDelivery?_format=json&_pretty=true`;

    if (id) url = `${url}&_content=Location/${oids.organization}-${id}`;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const supplyDelivery = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getSupplyDeliveryList'));

    if (typeof supplyDelivery === 'string') return supplyDelivery;
    return supplyDelivery;
  }

  static async createLocation({
    name,
    organization,
    address,
    phone,
    postalCode,
    uf,
    city,
    district,
  }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const url = `${FHIR}/Location`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const body = {
      resourceType: 'Location',
      status: 'active',
      name,
      address: {
        line: address,
        city,
        state: uf,
        postalCode,
        district,
      },
      telecom: [
        {
          system: 'phone',
          value: phone,
          use: 'work',
        },
      ],
      managingOrganization: {
        reference: `Organization/${oids.organization}-${organization}`,
      },
      hoursOfOperation: [
        {
          daysOfWeek: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
          allDay: true,
        },
      ],
    };
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };

    const fhirResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'createLocation'));

    if (typeof fhirResponse === 'string') return fhirResponse;
    return fhirResponse;
  }

  static async createMainLocation({
    name,
    organization,
    address,
    phone,
    postalCode,
    uf,
    city,
    district,
  }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const url = `${FHIR}/Location/${oids.organization}-${organization}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const body = {
      resourceType: 'Location',
      id: `${oids.organization}-${organization}`,
      status: 'active',
      name,
      address: {
        line: address,
        city,
        state: uf,
        postalCode,
        district,
      },
      telecom: [
        {
          system: 'phone',
          value: phone,
          use: 'work',
        },
      ],
      managingOrganization: {
        reference: `Organization/${oids.organization}-${organization}`,
      },
      hoursOfOperation: [
        {
          daysOfWeek: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
          allDay: true,
        },
      ],
    };
    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };

    const fhirResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'createMainLocation'));

    if (typeof fhirResponse === 'string') return fhirResponse;
    return fhirResponse;
  }

  static async editLocation({
    name,
    organization,
    address,
    phone,
    postalCode,
    uf,
    city,
    district,
    id,
  }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const url = `${FHIR}/Location/${id}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const currentLocationData = await fhirRequests.getLocation({ id });
    if (typeof currentLocationData === 'string') return currentLocationData;

    const body = {
      ...currentLocationData,
      id,
      name,
      address: {
        line: address,
        city,
        state: uf,
        postalCode,
        district,
      },
      telecom: [
        {
          system: 'phone',
          value: phone,
          use: 'work',
        },
      ],
      managingOrganization: {
        reference: `Organization/${oids.organization}-${organization}`,
      },
    };
    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };

    const fhirResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'editLocation'));

    if (typeof fhirResponse === 'string') return fhirResponse;
    return fhirResponse;
  }

  static async getPractitionerRoles({ identifier }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const url = `${ADMFHIR}/PractitionerRole?active=true&practitioner=Practitioner/${identifier}&role=ROLE_PRACTITIONER`;

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const FHIR_response = await fetchWithTimeout(url, requestOptions, 5000)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getPatientJson'));

    verifyUnauthorized(FHIR_response);
    return FHIR_response;
  }

  static async getPatientJson(username) {
    try {
      // eslint-disable-next-line no-param-reassign
      username = username.replace(/\D/g, '');
    } catch (err) {
      logDebug('Error em getPatientJson', err);
      username = null;
    }
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const patient_oid = verifyCPF(username) ? oids.cpf : oids.cns;

    const url = `${FHIR}/Patient/${patient_oid}-${username}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const timeout = 5000;
    const FHIR_response = await fetchWithTimeout(url, requestOptions, timeout)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getPatientJson'));

    verifyUnauthorized(FHIR_response);

    if (typeof FHIR_response === 'string') return FHIR_response;
    switch (FHIR_response.gender) {
      case 'male' || 'M' || 'm':
        FHIR_response.gender = 'Masculino';
        break;
      case 'female' || 'F' || 'f':
        FHIR_response.gender = 'Feminino';
        break;
      default:
        FHIR_response.gender = 'Outro';
    }
    return FHIR_response;
  }

  static async createVaccineBatch({
    organization,
    quantity,
    receivedAt,
    immunobiological,
    batch,
    expirationDate,
    code,
    manufacturerOrg,
  }) {
    logDebug(
      organization,
      quantity,
      receivedAt,
      immunobiological,
      batch,
      expirationDate,
    );

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const url = FHIR;

    const body = {
      resourceType: 'Bundle',
      entry: [
        {
          resource: {
            resourceType: 'SupplyDelivery',
            suppliedItem: {
              quantity: {
                value: quantity,
              },
              itemReference: {
                reference: 'urn:uuid:7292f2cb-d27c-4624-94d3-aa39ac8643f5',
              },
            },
            occurrenceDateTime: receivedAt,
            destination: {
              reference: `Location/${oids.organization}-${organization}`,
            },
          },
          request: {
            method: 'POST',
            url: 'SupplyDelivery',
          },
        },
        {
          fullUrl: 'urn:uuid:7292f2cb-d27c-4624-94d3-aa39ac8643f5',
          resource: {
            resourceType: 'Medication',
            code: {
              coding: [
                {
                  system:
                    'http://www.saude.gov.br/fhir/r4/CodeSystem/BRImunobiologico',
                  code,
                  display: immunobiological,
                },
              ],
            },
            manufacturer: {
              reference: `Organization/2.16.76.1.3.3-${manufacturerOrg}`,
            },
            batch: {
              lotNumber: batch,
              expirationDate,
            },
          },
          request: {
            method: 'POST',
            url: 'Medication',
          },
        },
      ],
    };

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };

    const fhirResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'CreateVaccineBatch'));

    if (typeof fhirResponse === 'string') return fhirResponse;
    return fhirResponse;
  }

  static async deleteVaccineBatch({ supplyDeliveryId, medicationId }) {
    logDebug('medicationId', medicationId);
    logDebug('supplyDeliveryId', supplyDeliveryId);

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const url = FHIR;

    const body = {
      resourceType: 'Bundle',
      // type: 'transaction',
      entry: [
        {
          request: {
            method: 'DELETE',
            url: `SupplyDelivery/${supplyDeliveryId}`,
          },
        },
        {
          request: {
            method: 'DELETE',
            url: `Medication/${medicationId}`,
          },
        },
      ],
    };

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };

    const fhirResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'CreateVaccineBatch'));

    if (typeof fhirResponse === 'string') return fhirResponse;
    return fhirResponse;
  }

  static async getMedicationsBundle(medicationIds) {
    const entries = medicationIds?.map((item) => ({
      request: {
        method: 'GET',
        url: item,
      },
    }));
    const resp = await fhirRequests.postBundle(entries);
    return resp;
  }

  static async getOrganizationsBundle(organizationIds) {
    const entries = organizationIds?.map((item) => ({
      request: {
        method: 'GET',
        url: item,
      },
    }));
    const resp = await fhirRequests.postBundle(entries);
    return resp;
  }

  static async getPractitionerRoleByOrganization(cnes) {
    const cnes_oid = oids.organization;

    const url = `${FHIR}/PractitionerRole?organization=${cnes_oid}-${cnes}&_include=PractitionerRole:practitioner`;
    await xdsToken.getBearerToken();
    const applicationToken = await retrieveData('application_token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    const token = await retrieveData('token');

    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const timeout = 15000;
    // eslint-disable-next-line prefer-const
    const FHIR_response = await fetchWithTimeout(url, requestOptions, timeout)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getPractitionerRoleByOrganization'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  /**
   *
   * @param uf Sigla da UF
   * @returns Todos profissionais da UF
   */
  static async getAdminPractitionerRolesByUf({
    uf,
    filter = {
      ROLE_GestorEstabelecimento: true,
      ROLE_GestorEstadual: true,
      ROLE_GestorMunicipal: true,
    },
  }) {
    const rolesFilter = Object.keys(filter)
      .filter((item) => (filter[item] === true ? item : ''))
      .join(',');
    // const roles = 'ROLE_GestorEstabelecimento,ROLE_GestorEstadual,RL_GestorEstabelecimento,RL_GestorEstadual';
    const url = `${FHIR}/PractitionerRole?role=${rolesFilter}&organization:Organization.address-state=${uf}&_include=PractitionerRole:practitioner`;
    await xdsToken.getBearerToken();
    const applicationToken = await retrieveData('application_token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);

    const token = await retrieveData('token');

    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const timeout = 15000;
    // eslint-disable-next-line prefer-const
    const FHIR_response = await fetchWithTimeout(url, requestOptions, timeout)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getPractitionerRoleByUf'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  static async getCodeSystem({ terminologyName = null }) {
    if (!terminologyName) return 'Missing terminologyName param';
    const terminologyList = {
      immunobiological: oids.immunobiological,
      orgType: oids.orgType,
      susAttendance: oids.susAttendance,
      legalNature: oids.legalNature,
      admType: oids.admType,
    };
    const code = terminologyList[terminologyName];
    const url = `${FHIR}/CodeSystem/${code}`;
    await xdsToken.getBearerToken();
    const applicationToken = await retrieveData('application_token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);

    const token = await retrieveData('token');

    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const timeout = 15000;
    const FHIR_response = await fetchWithTimeout(url, requestOptions, timeout)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getCodeSystem'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  static async getManufacturers({ codes = null }) {
    if (!codes) return 'Missing codes param';

    let url = `${FHIR}/Organization?_has:Medication:manufacturer:code=`;
    for (let i = 0; i < codes.length; i += 1) url = `${url}http://www.saude.gov.br/fhir/r4/CodeSystem/BRImunobiologico%7C${codes[i]},`;

    await xdsToken.getBearerToken();
    const applicationToken = await retrieveData('application_token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);

    const token = await retrieveData('token');

    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const timeout = 15000;
    const FHIR_response = await fetchWithTimeout(url, requestOptions, timeout)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getManufacturers'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  static async putHealthcareService({ body }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const url = `${FHIR}/HealthcareService/${body.id}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };

    const fhirResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'putHealthcareService'));

    // if (typeof fhirResponse === 'string') return fhirResponse;
    return fhirResponse;
  }

  static async postHealthcareService({ body }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const url = `${FHIR}/HealthcareService`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };

    const fhirResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'postHealthcareService'));

    // if (typeof fhirResponse === 'string') return fhirResponse;
    return fhirResponse;
  }

  static async postBundle(entry) {
    const url = `${FHIR}/`;
    const body = {
      entry,
      resourceType: 'Bundle',
    };

    await xdsToken.getBearerToken();
    const applicationToken = await retrieveData('application_token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');

    const token = await retrieveData('token');

    myHeaders.append('X-User-Token', token);
    // myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    };

    const timeout = 15000;
    // eslint-disable-next-line prefer-const
    const FHIR_response = await fetchWithTimeout(url, requestOptions, timeout)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'postBundle'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  static async uploadPdfRequest({ data, group, documentType }) {
    const patienceId = await retrieveData('username');
    const dataBase64 = data;
    const typeFile = documentType;
    const creationTime = new Date().toISOString();
    const documentRefId = uuid.v4();
    const timeMili = Math.floor(Math.random() * 1000000) + 1;
    const documentId = `1.3.6.1.4.1.54413.1.1.5.1.${timeMili}.2`;
    // const url = `${XDSB_domain}/mhd/fhir`;
    const url = `${USER_FHIR}/`;
    const body = {
      resourceType: 'Bundle',
      meta: {
        profile: [
          'http://ihe.net/fhir/StructureDefinition/IHE_MHD_Provide_Comprehensive_DocumentBundle',
        ],
      },
      type: 'transaction',
      entry: [
        {
          resource: {
            resourceType: 'DocumentReference',
            id: documentRefId,
            meta: {
              tag: [
                {
                  system:
                    'http://www.saude.gov.br/fhir/r4/CodeSystem/BRGrupoAtendimento',
                  code: group.code,
                  display: group.display,
                },
              ],
            },
            masterIdentifier: {
              system: 'urn:ietf:rfc:3986',
              value: `urn:oid:${documentId}`,
            },
            identifier: [
              {
                use: 'official',
                system: 'urn:ietf:rfc:3986',
                value: `urn:uuid:${documentRefId}`,
              },
            ],
            status: 'current',
            category: [
              {
                coding: [
                  {
                    system: '1.3.6.1.4.1.54413.1.1.2.4',
                    code: 'EligibilidadeCovid19',
                    display:
                      'Documentação comprobatória de eligibilidade para vacinação contra COVID-19',
                  },
                ],
              },
            ],
            subject: {
              reference: `Patient/${oids.cpf}-${patienceId}`,
            },
            date: creationTime,
            author: [
              {
                reference: `Patient/${oids.cpf}-${patienceId}`,
              },
            ],
            content: [
              {
                attachment: {
                  contentType: typeFile,
                  url: documentId,
                  size: 3028,
                  hash: 'bfd009f500c057195ffde66fae64f92fa5f59b72',
                },
              },
            ],
            context: {
              event: [
                {
                  coding: [
                    {
                      system: oids.programHealthCareService,
                      code: 'ImunizacaoCovid19',
                      display: 'Vacinação contra COVID-19',
                    },
                  ],
                },
              ],
              related: [
                {
                  reference: `Group/${group.id}`,
                },
              ],
            },
          },
          request: {
            method: 'PUT',
            url: `DocumentReference/${documentRefId}`,
          },
        },
        {
          resource: {
            resourceType: 'Binary',
            id: documentId,
            contentType: typeFile,
            data: dataBase64,
          },
          request: {
            method: 'PUT',
            url: `Binary/${documentId}`,
          },
        },
      ],
    };
    const applicationToken = await retrieveData('application_token');
    const token = await retrieveData('token');
    let purposeOfUse = await retrieveData('purposeOfUse');
    if (purposeOfUse === 'null') purposeOfUse = null;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    };
    // eslint-disable-next-line prefer-const
    const FHIR_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'uploadPdfRequest'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  static async getCnes(id = null) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const username = id || (await retrieveData('username'));
    // logDebug('username:', username);
    const url = `${ADMFHIR}/PractitionerRole?practitioner=Practitioner%2F${oids.cpf}-${username}&role=ROLE_PRACTITIONER`;
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const cneses = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        // logDebug('response', response);
        if (response.status !== 200) throw new Error(response.status);
        return response;
      })
      .catch((err) => fhirRequests.catchError(err, 'getCnes'));

    try {
      let response = await cneses.json();
      response = response.entry;
      let cnesList = response.map(
        (item) => item.resource.organization.reference,
      );
      cnesList = cnesList.map((item) => item.split('-')[1]);
      return cnesList;
    } catch (e) {
      return null;
    }
  }

  static async getBasicConfigsByCity(cityId) {
    // example id = 220040
    const token = await retrieveData('token');
    const applicationToken = await xdsToken.getApplicationToken();
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');
    myHeaders.append('cache-control', 'no-cache');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    const url = `${ADMFHIR}/Basic/1.3.6.1.4.1.54413.5.1.2.${
      String(cityId).length > 6 ? String(cityId).substring(0, 6) : cityId
    }`;
    const responseFetch = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => this.catchError(err, 'getBasicConfigsByCity'));
    return responseFetch;
  }

  static async getGroups({ cityId, patientId, code }) {
    // example id = 220040
    const token = await retrieveData('token');
    const applicationToken = await xdsToken.getApplicationToken();
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');
    let url = `${ADMFHIR}/Group?_elements=id,extension,active,code&characteristic-value=1.3.6.1.4.1.54413.1.1.6.12%7C$${cityId}&characteristic-value=${oids.programHealthCareService}%7C$ImunizacaoCovid19`;

    if (patientId) {
      url = `${url}&member=Patient/${
        verifyCPF(patientId) ? oids.cpf : oids.cns
      }-${patientId}`;
    }
    if (code) url = `${url}&code=${code}`;

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    const responseFetch = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => this.catchError(err, 'getBasicConfigsByCity'));
    return responseFetch;
  }

  static async getEligibilityDocumentReferences({ patientId, group }) {
    const patientOid = CNSisValid(patientId) ? oids.cns : oids.cpf;
    let url = `${USER_FHIR}/DocumentReference?category:coding=EligibilidadeCovid19`;
    url = `${url}&subject=Patient/${patientOid}-${patientId}`;
    if (group) url = `${url}&related=Group/${group}`;
    const applicationToken = await retrieveData('application_token');
    const token = await retrieveData('token');
    let purposeOfUse = await retrieveData('purposeOfUse');
    if (purposeOfUse === 'null') purposeOfUse = null;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    // eslint-disable-next-line prefer-const
    const FHIR_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'uploadPdfRequest'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  static async getBinary({ binaryId }) {
    const url = `${USER_FHIR}/Binary/${binaryId}`;
    const applicationToken = await retrieveData('application_token');
    const token = await retrieveData('token');
    let purposeOfUse = await retrieveData('purposeOfUse');
    if (purposeOfUse === 'null') purposeOfUse = null;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    // eslint-disable-next-line prefer-const
    const FHIR_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getBinary'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  static async deleteDocumentReference({ documentReferenceId }) {
    const url = `${USER_FHIR}/DocumentReference/${documentReferenceId}`;
    const applicationToken = await retrieveData('application_token');
    const token = await retrieveData('token');
    let purposeOfUse = await retrieveData('purposeOfUse');
    if (purposeOfUse === 'null') purposeOfUse = null;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    // myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    };

    const FHIR_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'deleteDocumentReferenceAndBinary'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  static async deleteDocumentReferenceAndBinary({
    documentReferenceId,
    binaryId,
  }) {
    const url = `${USER_FHIR}/`;
    const applicationToken = await retrieveData('application_token');
    const token = await retrieveData('token');
    let purposeOfUse = await retrieveData('purposeOfUse');
    if (purposeOfUse === 'null') purposeOfUse = null;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    // myHeaders.append('purposeofuse', purposeOfUse || 'Atendimento');
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const body = {
      resourceType: 'Bundle',
      meta: {
        profile: [
          'http://ihe.net/fhir/StructureDefinition/IHE_MHD_Provide_Comprehensive_DocumentBundle',
        ],
      },
      type: 'transaction',
      entry: [
        {
          request: {
            method: 'DELETE',
            url: `Binary/${binaryId}`,
          },
        },
        {
          request: {
            method: 'DELETE',
            url: `DocumentReference/${documentReferenceId}`,
          },
        },
      ],
    };

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    };

    const FHIR_response = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'deleteDocumentReferenceAndBinary'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  static async createOrganization({ orgInfos }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const id = `${oids.organization}-${orgInfos.cnes}`;
    const url = `${ADMFHIR}/Organization/${id}`;
    const state = orgInfos.uf.split('-')[1];

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const body = {
      address: [
        {
          city: orgInfos.city,
          country: 'Brasil',
          line: [
            {
              address: orgInfos.address,
            },
          ],
          postalCode: orgInfos.postalCode,
          state,
        },
      ],
      id,
      name: orgInfos.nameOrg,
      resourceType: 'Organization',
      telecom: [
        {
          system: 'phone',
          value: orgInfos.phone,
        },
      ],
    };

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };

    const fhirResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'createOrganization'));

    if (typeof fhirResponse === 'string') return fhirResponse;
    return fhirResponse;
  }

  static async getOrgHospital() {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/Organization`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const organizationJSON = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getOrgHospital'));

    if (typeof organizationJSON === 'string') return organizationJSON;
    return organizationJSON;
  }

  static async getHealthcareServicesFromLocation(locationID) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/HealthcareService?location=${locationID}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const healthcaresFHIR = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getHealthcareServicesFromLocation'));
    if (typeof healthcaresFHIR === 'string') return healthcaresFHIR;
    return healthcaresFHIR;
  }

  static async deleteHealthcareService(healthcareID) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/HealthcareService/${healthcareID}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    };

    const deleteConfirmation = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'deleteHealthcareService'));
    if (typeof deleteConfirmation === 'string') return deleteConfirmation;
    return deleteConfirmation;
  }

  static async createMockHealthcareService({ organizationID, locationID }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/HealthcareService`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const body = {
      name: 'Novo Serviço de Saúde',
      appointmentRequired: true,
      resourceType: 'HealthcareService',
      active: true,
      providedBy: {
        reference: `Organization/${organizationID}`,
      },
      location: [
        {
          reference: `Location/${locationID}`,
        },
      ],
    };

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    };

    const healthcareUpdated = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'createMockHealthcareService'));
    if (typeof healthcareUpdated === 'string') return healthcareUpdated;
    return healthcareUpdated;
  }

  static async updateHealthcareServiceName({
    organizationID,
    locationID,
    healthcareID,
    newName,
  }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/HealthcareService/${healthcareID}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const body = {
      name: newName,
      id: healthcareID,
      appointmentRequired: true,
      resourceType: 'HealthcareService',
      active: true,
      providedBy: {
        reference: `Organization/${organizationID}`,
      },
      location: [
        {
          reference: `Location/${locationID}`,
        },
      ],
    };

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    };

    const healthcareCreated = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'updateHealthcareServiceName'));
    if (typeof healthcareCreated === 'string') return healthcareCreated;
    return healthcareCreated;
  }

  static async getFirstScheduleFromLocation({ locationID }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/Schedule?actor=Location/${locationID}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const schedulesList = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getFirstScheduleFromLocation'));
    if (typeof schedulesList === 'string') return schedulesList;
    if (!schedulesList.entry) return {};
    const firstSchedule = schedulesList.entry[0]?.resource;
    return firstSchedule;
  }

  static async getAllSlotsFromScheduleFromDate({ scheduleID, date }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/Slot?start=${date}&schedule=${scheduleID}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const slotsList = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getAllSlotsFromScheduleFromDate'));
    if (typeof slotsList === 'string') return slotsList;
    return slotsList.entry;
  }

  static async getSlot({ slotID }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/Slot/${slotID}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const slotFHIR = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getAllSlotsFromScheduleFromDate'));
    if (typeof slotFHIR === 'string') return slotFHIR;
    return slotFHIR;
  }

  static async updateSlotStatus({ slotID, status }) {
    // Pega o resoure em formato FHIR do slot
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/Slot/${slotID}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const slotFHIR = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'updateSlotStatus'));
    if (typeof slotFHIR === 'string') return slotFHIR;

    // Faz o PUT com a atualização de status
    slotFHIR.status = status;
    requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(slotFHIR),
    };

    const updatedSlotFHIR = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'updateSlotStatus'));
    if (typeof updatedSlotFHIR === 'string') return updatedSlotFHIR;
    return updatedSlotFHIR;
  }

  static async createAppointmentOnSlot({ patientName, patientCPF, slotID }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/Appointment`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const body = {
      appointmentType: {
        coding: [
          {
            code: '0',
            display: 'Exame Teste',
            system: '0',
          },
        ],
      },
      created: new Date().toISOString(),
      participant: [
        {
          actor: {
            display: `${patientName}`,
            reference: `Patient/${oids.cpf}-${patientCPF}`,
          },
          required: 'required',
          status: 'needs-action',
        },
      ],
      priority: 0,
      resourceType: 'Appointment',
      slot: {
        reference: `Slot/${slotID}`,
      },
      status: 'booked',
    };

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    };

    const createdAppointment = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'createAppointmentOnSlot'));
    if (typeof createdAppointment === 'string') return createdAppointment;
    return createdAppointment;
  }

  static async getAllAppointmentsFromPatient({ patientCPF }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/Appointment?actor=Patient/${oids.cpf}-${patientCPF}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const appointmentsList = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getAllSlotsFromScheduleFromDate'));
    if (typeof appointmentsList === 'string') return appointmentsList;
    return appointmentsList.entry;
  }

  static async getAllOrganizationsFromAddress({ city, state }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/Organization?address-city=${city}&address-state=${state}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const organizationsList = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getAllSlotsFromScheduleFromDate'));
    if (typeof organizationsList === 'string') return organizationsList;
    return organizationsList.entry;
  }

  static async getAllLocationsFromOrganization({ organizationID }) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = `${ADMFHIR}/Location?organization=${organizationID}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const locationsList = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => fhirRequests.catchError(err, 'getAllLocationsFromOrganization'));
    if (typeof locationsList === 'string') return locationsList;
    return locationsList.entry;
  }
}

export default fhirRequests;
