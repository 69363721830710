import PropTypes from 'prop-types';
import React from 'react';
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import './PageMask.css';

import { Loading } from 'components';

import { currentProject } from 'configs';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

import { DropDownTranslate } from 'components/DropdownTranslate';

const StyledAppBar = withStyles({
  colorDefault: {
    background: 'white',
    color: 'black',
  },
})(AppBar);

function PageMask({ title, children, active }) {
  const loading = false;

  const { t } = useTranslation('PageMask', { i18n });

  const backgroundImageProject = `pagemask-container-${currentProject}`;

  return (
    <div className={`pagemask-container ${backgroundImageProject}`}>
      <StyledAppBar color="default" position="sticky">
        <Toolbar className="pagemask-toolbar">
          <Typography variant="h5">
            {title || t('pageMask-pageTitle-defaultPage')}
          </Typography>
          <DropDownTranslate />
        </Toolbar>
      </StyledAppBar>
      <Container maxWidth={false} disableGutters fixed>
        {loading || !active ? (
          <div className="pagemask-content pagemask-loading">
            <Loading size="large" type="circular" variant="determinate" />
          </div>
        ) : (
          <div className="pagemask-content">
            {children || <h1>{t('pageMask-renderPage-testRender')}</h1>}
          </div>
        )}
      </Container>
    </div>
  );
}

PageMask.defaultProps = {
  active: true,
};

PageMask.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
};

export { PageMask };
