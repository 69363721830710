import React, { useEffect, useState } from 'react';
import i18n from 'i18n';
import moment from 'moment';
import { Jumbotron, Modal } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Table, 
    TableBody, 
    TableCell, 
    TableHead, 
    TableRow
} from '@material-ui/core';
import { FaTrash } from 'react-icons/fa';

import consentRequests from 'services/consentRequests';
import policyRequests from 'services/policyRequests';
import MHDRequests from 'services/MHDRequests';
import { CNSisValid, retrieveData, verifyCPF } from 'utils/AuxiliarFunctions';
import { useVisualizerContext } from 'contexts/VisualizerContext';

import { Loading, PageError, SearchConsents, StandardInput, TabNav } from 'components';
import { Tab } from 'components/Tab';
import { TabPanel } from 'components/TabPanel';

import './ConsentsPage.css';
import AdminFhirRequests from 'services/AdminFhirRequests';

const ConsentsPage = () => {
    const { setVisualizerPatient } = useVisualizerContext();
    const history = useHistory();

    const [is_consent_modal_open, setIsConsentModalOpen] = useState(false);
    const [is_revoke_consent_open, setIsRevokeConsentOpen] = useState(false);
    const [is_consent_done_open, setIsConsentDoneOpen] = useState(false);
    const [is_consent_error_open, setIsConsentErrorOpen] = useState(false);
    const [has_policy_error, setHasPolicyError] = useState(false);

    const [current_institution, setCurrentInstitution] = useState(null);
    const [document_for_share, setDocumentForShare] = useState(null);
    const [search_patient, setSearchPatient] = useState({
        id: '',
        name: ''
    });
    const [alert_infos, setAlertInfos] = useState({
        isModalOpen: false,
        title: '',
        description: ''
    });

    const [internalErr, setInternalErr] = useState(false);
    const [is_loading, setIsLoading] = useState(false);

    const [granted_consents, setGrantedConsents] = useState([]);
    const [granted_inst_consents, setGrantedInstConsents] = useState([]);
    const [received_consents, setReceivedConsents] = useState([]);
    const [list_to_render, setListToRender] = useState([]);

    const t = (translations, customGroup) => {
        return i18n.t(translations, { ns: customGroup ? customGroup : 'ConsentsPage' });
    }

    const onClickCpf = (cpf) => setVisualizerPatient({ patientId: cpf });

    const isPolicyOptIn = async () => {
      const cpf = await retrieveData('username');
      const policy = await policyRequests.getPolicyRequest(cpf);
      if (typeof policy !== 'string') {
        if (policy.politicaRES === 'Opt-in') return true;
      }
      
      if (policy === 'internal_error' || !policy) {
        setInternalErr(true);
      }

      return false;
    }

    const getInstitutionConsents = async (consents) => {
        const institution_consents = await Promise.all(consents.map(async (consent) => {
            const institution = await AdminFhirRequests.getOrganizationName(consent.contexto.instituicao);
            
            return {
                ...consent,
                nome: institution
            };
        }))

        setGrantedInstConsents(institution_consents);
    }

    const formatListToRender = (list, sujeitoReference = 'sujeito') => {
        return list.map((item) => {
            if (item.sujeito.id === null) {
                return {
                    pat_name: item[sujeitoReference].nome,
                    pat_id: item.contexto.instituicao,
                    date: moment(item.hora).format('DD/MM/YYYY'),
                    institution: item.contexto.instituicao
                }
            }

            return {
                pat_name: item[sujeitoReference].nome,
                pat_id: item[sujeitoReference].id,
                date: moment(item.hora).format('DD/MM/YYYY')
            }
        });
    }

    const getConsents = async () => {
        const username = await retrieveData('username');
        
        const received_resp = await consentRequests.getReceivedConsents(username);
        const consents_resp = await consentRequests.getConsents(username);
        
        if (typeof consents_resp === 'string' || typeof received_resp === 'string') {
            return;
        }
        const authorized_consents = consents_resp.filter((consent) => consent.acao === 'Autorizar');
        const granted_consents = authorized_consents.filter((consent) => consent.sujeito.id !== null);
        const granted_inst_consents = authorized_consents.filter((consent) => consent.sujeito.id === null && consent.contexto.instituicao !== null);
        setGrantedConsents(granted_consents);
        getInstitutionConsents(granted_inst_consents);
        setReceivedConsents(received_resp.filter((consent) => consent.sujeito.id !== null && consent.acao === 'Autorizar'));
        setListToRender([
            formatListToRender(granted_consents), 
            formatListToRender(received_resp.filter((consent) => consent.sujeito.id !== null), 'recurso'),
            formatListToRender(granted_inst_consents)
        ]);
    }

    const getData = async () => {
        setIsLoading(true);
        const isOptIn = await isPolicyOptIn();
        setHasPolicyError(!isOptIn);
        await getConsents();
        setIsLoading(false);
    }

    useEffect(() => {
        (async () => await getData())();
    }, [])

    const removeConsent = async (patient_id) => {
        setIsLoading(true);
        const subject_id = await retrieveData('username');
        
        const response = current_institution
          ? await consentRequests.appc_request('Deny', subject_id, null, [patient_id])
          : await consentRequests.appc_request('Deny', subject_id, [patient_id]);
        await getConsents();
    
        if (typeof response === 'string') setIsConsentErrorOpen(true);
        else setIsConsentDoneOpen(true)
    
        setIsRevokeConsentOpen(false);
        setIsLoading(false);
        setCurrentInstitution(null);
    }

    const canShareDocument = async (document_for_share, is_document_valid) => {
        const patient_id = await retrieveData('username');
        
        if (document_for_share === patient_id) {
            setAlertInfos({
                isModalOpen: true,
                title: t('ConsentsPage-addConsents-invalidOperation'),
                description: t('ConsentsPage-addConsents-notPossibleShare')
            })
            setIsLoading(false);
            return false;
        }
        const current_consents = is_document_valid 
            ? granted_inst_consents.find((consent) => consent.sujeito.id === patient_id) 
            : granted_consents.find((consent) => consent.sujeito.id === patient_id);
        
        if (current_consents !== undefined) {
            if (is_document_valid) {
                setAlertInfos({
                    isModalOpen: true,
                    title: t('ConsentsPage-addConsents-alreadyShared'),
                    description: `${t('ConsentsPage-addConsents-alreadySharedRegister')}${(current_consents.sujeito.nome === 'not_found' || current_consents.sujeito.nome === null) ? t('ConsentsPage-addConsents-thisPerson') : current_consents.sujeito.nome}!`
                });
                setIsLoading(false);
                return false;
            } else {
                setAlertInfos({
                    isModalOpen: true,
                    title: t('ConsentsPage-addConsentsOrgs-alreadySharedOrgs'),
                    description: `${t('ConsentsPage-addConsentsOrgs-alreadySharedOrgsRegister')} ${(current_consents.sujeito.nome === 'not_found' || current_consents.sujeito.nome === null) ? t('ConsentsPage-addConsentsOrgs-thisOrgs') : current_consents.sujeito.nome}!`
                });
                setIsLoading(false);
                return false;
            }
        }

        return true;
    }

    const onShareSuccess = async (is_document_valid) => {
        let display_name = '';

        if (!is_document_valid) {
            display_name = await MHDRequests.getPatientJson({ patientId: document_for_share });
            if (typeof display_name !== 'string') {
                display_name = display_name.name[0].text;
            } else {
                display_name = `${t('ConsentsPage-makeConsents-displayName')} ${document_for_share}`;
                setSearchPatient({ ...search_patient, name: display_name });
            }
        } else {
            display_name = await MHDRequests.getOrganizationName(document_for_share);
            setSearchPatient({ ...search_patient, name: display_name });
        }
  
        await getConsents();

        if (!display_name || display_name === 'null') {
            setAlertInfos({
                isModalOpen: true,
                title: t('ConsentsPage-makePermitConsent-Success'),
                description: t('ConsentsPage-makePermitConsent-successShared')
            });
        } else {
            setAlertInfos({
                isModalOpen: true,
                title: t('ConsentsPage-makePermitConsent-Success'),
                description: `${t('ConsentsPage-makePermitConsent-thisMoments')} ${display_name} ${t('ConsentsPage-makePermitConsent-registerShared')}`
            });
        }
    }

    const onShareConsent = async () => {
        setIsLoading(true); 

        const patient_id = await retrieveData('username');
        const is_document_valid = !(verifyCPF(document_for_share) || CNSisValid(document_for_share));

        if (await canShareDocument(document_for_share, is_document_valid)) {
            const response = is_document_valid 
                    ? await consentRequests.appc_request('Permit', patient_id, null, [document_for_share]) 
                    : await consentRequests.appc_request('Permit', patient_id, [document_for_share]);
    
            if (typeof response === 'string') {
                setAlertInfos({
                    isModalOpen: true,
                    title: t('ConsentsPage-makePermitConsent-Error'),
                    description: t('ConsentsPage-makePermitConsent-haveAProblem')
                });
                setIsLoading(false);
                return;
            }
            
            await onShareSuccess(is_document_valid);
        }
        setIsConsentModalOpen(false);
        setIsLoading(false);
    }

    const onRemoveConsent = async (consent) => {
        setIsRevokeConsentOpen(true);
        let search_patient = {
            name: consent.sujeito.nome,
            id: consent.sujeito.id
        };

        if (!consent.sujeito.nome) {
            if (consent.contexto.instituicao) {
                const name = await MHDRequests.getOrganizationName(consent.contexto.instituicao);
                search_patient = {
                    id: consent.contexto.instituicao,
                    name
                }
                setCurrentInstitution(consent.contexto.instituicao);
            } else {
                const patient = await MHDRequests.getPatientJson({ patientId: consent.sujeito.id });
                if (typeof patient !== 'string') {
                    search_patient = {
                        id: consent.recurso.id,
                        name: patient.name[0].text
                    }
                }
            }
        }

        if (search_patient.name === 'not_found' || search_patient.name === 'network_error') {
            search_patient.name = `${t('ConsentsPage-clickRemove-personWithCPF')} ${consent.sujeito.id}`;
        }

        setSearchPatient(search_patient);
    }

    const onClickRemove = async (patient_id, patient_nameArg = null, institution = null) => {
      let patient_name = patient_nameArg;
      if (!patient_name) {
        if (institution) patient_name = await MHDRequests.getOrganizationName(institution);
        else {
          patient_name = await MHDRequests.getPatientJson({ patientId: patient_id });
          if (typeof patient_name !== 'string') patient_name = patient_name.name[0].text;
        }
      }
  
      if (patient_name === 'not_found' || patient_name === 'network_error') patient_name = `${t('ConsentsPage-clickRemove-personWithCPF')} ${patient_id}`;
  
      setSearchPatient({
        id: patient_id,
        name: patient_name
      })
      setCurrentInstitution(institution);
      setIsRevokeConsentOpen(true);
    }

    const modals = (
        <>
            <Modal
                show={has_policy_error}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                    <h2 className="consent-modal-title">
                        {t('ConsentsPage-policyInfo-changeYourPolicy')}
                    </h2>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="consent-modal-text">
                        {t('ConsentsPage-policyInfo-notSharedRegister')}
                    </p>
                    <p className="consent-modal-text">
                        {`${t('ConsentsPage-policyInfo-controlYourShared')}${' '}
                        ${t('ConsentsPage-policyInfo-controlPrivicy')}`}
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={async () => window.location.href = '#/privacidade'}
                    >
                        {t('ConsentsPage-policyInfo-confirmToChangeYourPolicy')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={is_revoke_consent_open}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>{t('ConsentsPage-policyInfo-cancelThisConsents')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        {`${t('ConsentsPage-policyInfo-attentionThisAction')}
                        ${search_patient.name}
                        ${t('ConsentsPage-policyInfo-dontAcessRegister')}`}
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button 
                        color="default" 
                        variant="contained" 
                        onClick={() => setIsRevokeConsentOpen(false)}
                    >
                        {t('ConsentsPage-policyInfo-buttomCancel')}
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={async () => await removeConsent(search_patient.id)}
                    >
                        {t('ConsentsPage-policyInfo-buttomConfirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={is_consent_error_open}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>{t('ConsentsPage-consentsOrder-titleError')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('ConsentsPage-consentsOrder-msgError')}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="outlined" 
                        color="default" 
                        onClick={() => setIsConsentErrorOpen(false)}
                    >
                        {t('ConsentsPage-consentsOrder-buttomClose')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={is_consent_done_open}>
                <Modal.Header>
                    <Modal.Title>{t('ConsentsPage-consentsOrder-titleSucess')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        {search_patient.name 
                            ? `${t('ConsentsPage-consentsOrder-textSucessRemoveInicial')} ${search_patient.name} ${t('ConsentsPage-consentsOrder-textSucessRemoveMiddle')}`
                            : `${t('ConsentsPage-consentsOrder-consentsRemove')}`
                        }
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button 
                        variant="outlined" 
                        color="default" 
                        onClick={() => setIsConsentDoneOpen(false)}
                    >
                        {t('ConsentsPage-consentsOrder-buttomClose')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={is_consent_modal_open}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* <TabNav
                    titles={[{
                        value: 'people', 
                        label: t('ConsentsPage-tabNav-people'), 
                        position: 'top', 
                        icon: <FaUserFriends size="3rem" />,
                    }]}
                /> */}

                <Modal.Header>
                    <Modal.Title>
                        {t('ConsentsPage-consentsAdd-newShare')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>{t('ConsentsPage-consentsAdd-writeDataPerson')}</p>
                    <div className="human-entry-input">
                        <StandardInput
                            maxLength="50"
                            className="consentGiveConsentInput"
                            type="text"
                            id="toGiveConsent"
                            placeholder=""
                            onChange={(value) => setDocumentForShare(value)}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        color="custom"
                        variant="contained"
                        onClick={onShareConsent}
                    >
                        {t('ConsentsPage-consentsAdd-Share')}
                    </Button>
                    <Button 
                        variant="outlined" 
                        color="secondary" 
                        onClick={() => setIsConsentModalOpen(false)}
                    >
                        {t('ConsentsPage-consentsOrder-buttomClose')}
                    </Button>
                </Modal.Footer>

            </Modal>
            <Modal
                show={alert_infos.isModalOpen}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>{alert_infos.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{alert_infos.description}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="outlined" color="secondary"
                        onClick={() => setAlertInfos({
                            isModalOpen: false,
                            title: '',
                            description: ''
                        })}
                    >
                        {t('ConsentsPage-consentsOrder-buttomClose')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

    const emptyList = () => (
        <div className="d-flexs justify-content-center w-100">
            <Jumbotron
                className="accent-color-secondary mb-0"
                style={{ backgroundColor: 'transparent', padding: '16px 0' }}
            >
                <p style={{ textAlign: 'center' }}>
                    <Icon name="file text" style={{fontSize: 20}}/>
                </p>
                <p style={{ textAlign: 'center', fontSize: 14 }}>
                    {t('ConsentCard-listShare-dontShare')}
                </p>
            </Jumbotron>
        </div>
    )

    if (internalErr) return <PageError />;
        
    if (is_loading) return (
        <div className="consentsPage-loading">
            <Loading size="large" />
        </div>
    );

    
    return (
        <>
            <div className='consentsPage-container'>
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <h1 className='mb-0'>{t('pages-titlePages-nameShare', 'PageMask')}</h1>
                    <div className="d-flex align-items-center">
                        <Button 
                            variant="contained"
                            color="primary"
                            style={{ background: '#08824A' }}
                            className='mr-2'
                            onClick={() => setIsConsentModalOpen(true)}
                        >
                            {t('ConsentsPage-buttomConsents-newConsents')}
                        </Button>
                        <Button 
                            variant="outlined"
                            color="default"
                            onClick={() => history.push('/historico-acesso')}
                            // onClick={searchPatient}
                        >
                            {t('ConsentsPage-history-acessHistory')}
                        </Button>
                    </div>
                </div>
                <SearchConsents
                    placeholder={t('ConsentsPage-searchShared-placeholderSearch')}
                    listToRender={list_to_render}
                    handleSearch={[onClickCpf, onClickRemove]}
                />
                <Tab className="consents-tab">
                    <TabPanel title={t('ConsentsPage-buttomViewShared-labelPerson')}>
                        <Accordion className='consents-accordion' defaultExpanded={received_consents.length > 0}>
                            <AccordionSummary className='px-0'>
                                <div className="d-flex align-items-center consents-table-title">
                                    <Icon name='chevron up' size='small' className='mr-2' />
                                    <h3 className='m-0'>{t('ConsentsPage-titlePage-received')}</h3>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className='px-0'>
                                {received_consents.length === 0 
                                    ? emptyList() 
                                    : (
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><h5>CPF/CNS</h5></TableCell>
                                                    <TableCell><h5>Nome</h5></TableCell>
                                                    <TableCell><h5>Data</h5></TableCell>
                                                    <TableCell><h5>Hora</h5></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {received_consents.map((item, index) => (
                                                    <TableRow 
                                                        hover 
                                                        key={`received-item-${index}`}
                                                        onClick={() => onClickCpf(item.recurso.id)}
                                                    >
                                                        <TableCell>{item.recurso.id}</TableCell>
                                                        <TableCell>{item.recurso.nome}</TableCell>
                                                        <TableCell>{moment(item.hora).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell>{moment(item.hora).format('HH:mm')}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )
                                }
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className='consents-accordion' defaultExpanded={granted_consents.length > 0}>
                            <AccordionSummary className='px-0'>
                                <div className="d-flex align-items-center consents-table-title">
                                    <Icon name='chevron up' size='small' className='mr-2' />
                                    <h3 className='m-0'>{t('ConsentsPage-titlePage-granted')}</h3>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className='px-0'>
                                {granted_consents.length === 0 
                                    ? emptyList() 
                                    : (
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><h5>CPF/CNS</h5></TableCell>
                                                    <TableCell><h5>Nome</h5></TableCell>
                                                    <TableCell><h5>Data</h5></TableCell>
                                                    <TableCell><h5>Hora</h5></TableCell>
                                                    <TableCell style={{textAlign: 'center'}}><h5>Ação</h5></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {granted_consents.map((item, index) => (
                                                    <TableRow 
                                                        key={`granted-item-${index}`}
                                                    >
                                                        <TableCell>{item.sujeito.id}</TableCell>
                                                        <TableCell>{item.sujeito.nome}</TableCell>
                                                        <TableCell>{moment(item.hora).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell>{moment(item.hora).format('HH:mm')}</TableCell>
                                                        <TableCell style={{textAlign: 'center'}}>
                                                            <button onClick={() => onRemoveConsent(item)} className='cancel-btn'>
                                                                <FaTrash size={14} color='red'/>
                                                            </button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )
                                }
                            </AccordionDetails>
                        </Accordion>
                    </TabPanel>
                    <TabPanel title={t('ConsentsPage-buttomViewShared-labelUnitedHealth')}>
                        <Accordion className='consents-accordion' defaultExpanded={granted_inst_consents.length > 0}>
                            <AccordionSummary className='px-0'>
                                <div className="d-flex align-items-center consents-table-title">
                                    <Icon name='chevron up' size='small' className='mr-2' />
                                    <h3 className='m-0'>{t('ConsentsPage-titlePage-granted')}</h3>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className='px-0'>
                                {granted_inst_consents.length === 0 
                                    ? emptyList() 
                                    : (
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><h5>CNES/CNPJ</h5></TableCell>
                                                    <TableCell><h5>Nome</h5></TableCell>
                                                    <TableCell><h5>Data</h5></TableCell>
                                                    <TableCell><h5>Hora</h5></TableCell>
                                                    <TableCell style={{textAlign: 'center'}}><h5>Ação</h5></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {granted_inst_consents.map((item, index) => (
                                                    <TableRow key={`grant-inst-item-${index}`}>
                                                        <TableCell>{item.contexto.instituicao}</TableCell>
                                                        <TableCell>{item.nome}</TableCell>
                                                        <TableCell>{moment(item.hora).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell>{moment(item.hora).format('HH:mm')}</TableCell>
                                                        <TableCell style={{textAlign: 'center'}}>
                                                            <button onClick={() => onRemoveConsent(item)} className='cancel-btn'>
                                                                <FaTrash size={14} color='red'/>
                                                            </button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )
                                }
                            </AccordionDetails>
                        </Accordion>
                    </TabPanel>
                </Tab>
            </div>
            {modals}
        </>
    )
}

export default ConsentsPage;