import React from 'react';
import './ArchetypeMenu.css';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ArchetypeButton from './ArchetypeButton';

const ArchetypeMenu = (archetypes, selectArchetype, item) => (
  <div>
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Nav className="flex-column">
        {archetypes.map((columns) => (
          <div>
            {columns.map((column) => (
              <Nav.Item style={item === column ? { background: 'rgb(6, 78, 45)' } : null}>
                {column !== '' && (
                <Nav.Link eventKey={item === column ? 'first' : null}>
                  {ArchetypeButton(selectArchetype, item, column)}
                </Nav.Link>
                )}
              </Nav.Item>
            ))}
          </div>
        ))}
      </Nav>

    </Tab.Container>
  </div>
);

export { ArchetypeMenu };
