import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
} from '@material-ui/core';
import { formatDateDisplay } from 'utils/dateFunctions';
import { useTranslation } from 'react-i18next';

function ImmunizationTabContent({ documents, selectReport }) {
  const { t } = useTranslation('PatientSummary');

  const headers = [
    t('myRegisters-patientSummary-healthSummary-tab-column-dose'), //mudar para dose
    t('myRegisters-patientSummary-healthSummary-tab-column-vaccine'), //mudar para vacina
    t('myRegisters-patientSummary-healthSummary-tab-column-date')
  ];
  
  const rows = documents.map((document) => {
    const immunization = document.vaccineCode.coding[0];
console.log('document', document)
    console.log('document.protocolApplied.doseNumberString', document.protocolApplied.doseNumberString)

    return ({
      id: document.id,
      description: immunization.display,
      dose: document.protocolApplied[0].doseNumberString,
      date: formatDateDisplay(document.occurrenceDateTime),
    });
  });

  return (
    <>
      <Table
        size="small"
        aria-label="enhanced table"
      >
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell
                key={i}
              >
                <h5>
                  {header}
                </h5>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.map((document) => (
              <Fragment key={document.id}>
                <Tooltip
                  placement="top"
                  arrow
                  title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
                >
                  <TableRow
                    hover
                  >
                    <TableCell padding="checkbox">
                      {document.dose}
                    </TableCell>
                    <TableCell>
                      {document.description}
                    </TableCell>
                    <TableCell padding="checkbox">
                      {document.date}
                    </TableCell>
                  </TableRow>
                </Tooltip>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </>
  );
}

ImmunizationTabContent.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectReport: PropTypes.func.isRequired,
};

export { ImmunizationTabContent };
