import React, { useEffect, useState } from 'react';
import { Button, CustomTab } from 'components';
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import { Grid, RadioGroup } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { toast } from 'react-toastify';
import {
    findCommonElements,
    logDebug
} from 'utils/AuxiliarFunctions';  
import MHDRequests from 'services/MHDRequests';
import { SimpleModal, Loading } from 'components';
import { NewPatientFoundInfoAndAccess } from './NewPatientFoundInfoAndAccess';
import { NewPatientsList } from './NewPatientsList';
import fhirRequests from 'services/fhirRequests';
import { Switch, TextField, Checkbox, FormControl, FormLabel, FormControlLabel, Radio } from "@material-ui/core";
import { CNSisValid, cpfValidator } from 'utils/AuxiliarFunctions';

import './NewSearchPatient.css';
import { DropdownOrganization } from 'components/DropdownOrganization';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import sub from 'date-fns/sub';
import { formatDateDisplay } from 'utils/dateFunctions';
import { getAllPatientCnes } from 'utils/userFunctions';

function NewSearchPatient() {
    const { t } = useTranslation('NewSearchPatient', { i18n });

    const [allProfessionalCnes, setAllProfessionalCnes] = useState(null);
    const [searchDoc, setSearchDoc ] = useState('');
    const [searchName, setSearchName] = useState('');
    const [birthday, setBirthday] = useState(null);
    const [modal, setModal] = useState({
        visible: false,
        cancelButton: 'Fechar',
        maxWidth: 'sm',
      });
    const [selectedPatient, setSelectedPatient] = useState({});
    const [showResult, setShowResult] = useState(false);
    const [showResultList, setShowResultList] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [advancedSearchMode, setAdvancedSearchMode] = useState(false);
    const [gender, setGender] = useState(null);
    const [showHealthUnitModal, setShowHealthUnitModal] = useState(false);
    const [patientsToList, setPatientsToList] = useState([]);
    const [allPatientCnes, setAllPatientCnes] = useState([]);
    const [commonCnes, setCommonCnes] = useState([]);

    useEffect(() => {
        async function getAllProfessionalCnes() {
            try {
              const cnesProfessional = await fhirRequests.getCnes();
              setAllProfessionalCnes(cnesProfessional);
            } catch (err) {
              logDebug('SearchPatient getAllProfessionalCnes :>>', err);
            }
          }

          getAllProfessionalCnes();
      
    }, []);

    const patientNotFound = () => ({
        name: t('SearchPatient-patientNotFound-nameField'),
        birthdate: null,
        gender: 'NA',
        identifier: null,
      });    

    const extractInfoFromMHD = ({ patientJSON }) => {
        let gender = '';
        let name = '';
        let birthdate = '';
        try {
          name = patientJSON.name[0].text;
          birthdate = patientJSON?.birthDate;
          if (
            patientJSON.gender.toLowerCase() === 'male'
            || patientJSON.gender.toLowerCase() === 'masculino'
          ) gender = 'Masculino';
          if (
            patientJSON.gender.toLowerCase() === 'female'
            || patientJSON.gender.toLowerCase() === 'feminino'
          ) gender = 'Feminino';
          if (
            patientJSON.gender.toLowerCase() === 'other'
            || patientJSON.gender.toLowerCase() === 'outro'
          ) gender = 'Outro';
        } catch (err) {
          logDebug('SearchPatient extractInfoFromMHD :>>', err);
          return patientNotFound;
        }
        return ({
          name, gender, birthdate,
        });
      };
    

    const getPatientMHD = async ({ patientId }) => {
        const patientJSON = await MHDRequests.getPatientJson({ patientId });
        if (typeof patientJSON === 'object') {
          const patient = extractInfoFromMHD({ patientJSON });
          return { ...patient, identifier: patientId };
        }
        if (patientJSON === 'not_found') {
          setModal({
            ...modal,
            visible: true,
            title: 'Não Encontrado',
            body: 'O paciente da pesquisa não foi encontrado',
          });
        } else if (patientJSON === 'network_error') {
          setModal({
            ...modal,
            visible: true,
            title: 'Erro',
            body: 'Ocorreu um erro. Tente novamente mais tarde.',
          });
        }
        return patientNotFound;
      };
        
    async function searchPatient() {
      setShowResultList(false);
      setShowResult(false);
      
      if (advancedSearchMode) {
        advancedSearch();
      } else {
        simpleSearchInternal();
      }

    }


  const extractPatientsInfoFromSearch = ({ patientsArray }) => {
    if (!Array.isArray(patientsArray)) return [];
    const patientsExtracted = patientsArray.map((item) => {
      try {
        const pat = item.resource;
        let name = '';
        let birthdate = '';
        let identifier = '';
        const { gender } = pat;

        identifier = pat.identifier.map((val) => {
          if (val?.type?.text === 'CNS') return val.value;
          return null;
        });
        identifier = identifier.filter((val) => val)[0];

        name = pat.name.filter((val) => {
          if (val.use === 'official') return val;
          return null;
        });
        name = name.filter((val) => val)[0]?.text;

        birthdate = formatDateDisplay(pat.birthDate);

        return ({
          name,
          gender,
          birthdate,
          identifier,
        });
      } catch (err) {
        logDebug('SearchPatient extractPatientsInfoFromSearch :>> ', err);
        return patientNotFound;
      }
    });

    return patientsExtracted;
  };


    const advancedSearch = async () => {
      setShowResultList(false);
      setLoading(true);
      try {  
        if (birthday) {
          var dateWithGMT0 = sub(birthday, {
            minutes: birthday.getTimezoneOffset(),
          });
        }
  
        if (!searchName || searchName.trim() == '') {
          toast.error(t('NewSearchPatient-invalidName'));
          return;
        }

        if (!birthday || !JSON.stringify(birthday)) {
          toast.error(t("NewSearchField-birthday-required"));
          return;
        }
  
        const dateParam = birthday
        ? birthday.toISOString().split('T')[0] : null;
  
        const patientsFound = await MHDRequests.advancedSearch(
          searchName, dateParam, gender === 'outro' ? null : gender
        );
    
        const refinarPesquisa = patientsFound === 'network_error' || patientsFound?.total > 20;
  
        if (typeof patientsFound === 'string' && patientsFound !== 'network_error') {
          setModal({
            ...modal,
            title: 'Erro',
            body: 'Ocorreu um erro. Tente novamente mais tarde',
            visible: true,
          });
        } else if (refinarPesquisa || patientsFound?.total === 0) {
          setModal({
            ...modal,
            title: 'Paciente não encontrado',
            body: refinarPesquisa
              ? 'Para refinar a pesquisa, utilize o campo Data de Nascimento e Sexo e lembre-se de pesquisar por nome completo.'
              : 'Nenhum paciente foi encontrado.',
            visible: true,
          });
        } else {
          const preparedPatientsList = extractPatientsInfoFromSearch({
            patientsArray: patientsFound?.entry,
          });

          setPatientsToList(preparedPatientsList);
          setShowResultList(true);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }


    }

    const simpleSearchInternal = async () => {

      simpleSearch(searchDoc);

    }


    const simpleSearch = async (patientIdentifier) => {

      setShowResultList(false);
      setShowResult(false);

      if (patientIdentifier == '' || (!cpfValidator(patientIdentifier) && !CNSisValid(patientIdentifier))) {
          toast.error(t('NewSearchPatient-invalidIdentifier'));
          setShowResult(false);
      } else {
          setLoading(true);
          const allPatientCnes = await getAllPatientCnes(patientIdentifier);
          
          const commonCnes = findCommonElements(allPatientCnes,
              allProfessionalCnes);

          const patientMHD = await getPatientMHD({
              patientId: patientIdentifier,
            });

          if (!patientMHD.identifier) {
            setLoading(false);
            return;
          }

          setSelectedPatient(patientMHD);
          setCommonCnes(commonCnes);
          setAllPatientCnes(allPatientCnes);
          setShowResult(true);
          setLoading(false);
      }

    }


    const switchModeButton = () => (
        <div className="newSearchPatient-switchContainer">
            <div className="newSearchField-fieldname">{t('NewSearchPatient-advancedSwitchDescription')}</div>
            <Switch
                checked={checked}
                label="BuscaAvançada"
                labelPlacement="top"
                color="primary"
                onChange={(e) => {
                    setChecked(!checked);
                    if (!checked) {
                        setAdvancedSearchMode(true);
                    } else {
                        setAdvancedSearchMode(false);
                    }
                }}
                />
        </div>
    );

    const nameField = () => (
        <div className="newSearchField-container newSearchField-name">
            <span className="newSearchField-fieldname">{t('NewSearchField-patient-searchField')}&nbsp;*</span>
            <div className="newSearchField-searchbox">
                                                                
                <TextField
                    fullWidth
                    value={searchName}
                    onChange={async (e) => {
                        let newValue = e.target.value.replace(/[^a-zA-Z\s]+/g, '');
                        newValue = newValue.toUpperCase();
                        setSearchName(newValue);
                    }}
                    onKeyDown={async (e) => {
                        if (e.key == 'Enter') {
                            searchPatient();
                        }
                    }}
                    placeholder={t('NewSearchField-nameField-placeholder')}
                    />  
                
            </div>
        </div>
    )

    const identifier = () => (
        <div className="newSearchField-container">
            <span className="newSearchField-fieldname">{t('NewSearchField-patient-searchField')}</span>
            <div className="newSearchField-searchbox">
                                                                
                <TextField
                    fullWidth
                    value={searchDoc}
                    onChange={async (e) => {
                        const newValue = e.target.value.replace(/\D/g, '');
                        setSearchDoc(newValue);
                    }}
                    onKeyDown={async (e) => {
                        if (e.key == 'Enter') {
                            searchPatient();
                        }
                    }}
                    placeholder={t('NewSearchField-identifierField-placeholder')}
                    />  
                
            </div>
        </div>
    )

    const birthDateField = () => (
      <div className="newSearchPatient-birthdayContainer">
        <div className="newSearchField-fieldname">{t('SearchField-birthDateField-label')}&nbsp;*</div>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
            <KeyboardDatePicker
              InputLabelProps={{ shrink: true }}
              size="medium"
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={birthday}
              onChange={updateBirthday}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{width: '11rem', marginTop: '0.5rem'}}
              invalidDateMessage="Data inválida"
              />
          </MuiPickersUtilsProvider> 
        </div>
    )

    const updateBirthday = (birthday) => {
      setBirthday(birthday);
    }

    const updateGender = (event) => {        
        if (gender == event.target.value) {
          setGender(null);
        } else {
          setGender(event.target.value);
        }
    }

    const genderField = () => (
        <FormControl component="fieldset">
            <div className="newSearchField-fieldname">{t('NewSearchPatient-gender')}</div>

            <RadioGroup name="genero" value={gender} row>
                <FormControlLabel value="female" control={ <Radio onClick={updateGender} /> } label={t('NewSearchPatient-genderFemale')} />
                <FormControlLabel value="male" control={ <Radio onClick={updateGender} /> } label={t('NewSearchPatient-genderMale')} />                
            </RadioGroup>
        </FormControl>
    )

    const selectHealthUnitModal = () => (
      <SimpleModal
          setModal={() => setShowHealthUnitModal(false)}
          noCancelButton={true}
          okButton={true}
          modal={{
                  title: t('NewSearchPatient-chooseOrgToContinue'),
                  body: (
                      <DropdownOrganization showTitle={false} style={{ width: '60%' }} />
                  ),
                  visible: showHealthUnitModal
              }}
          />
  )

    return ( 
        <div className="newSearchPatient-outer">
            <div className="newSearchPatient-section">       
                <CustomTab type="person" title={t('NewSearchPatient-patientSearch')}>
                    <div className="newSearchPatient-container">
                        <Grid container>
                            <Grid item xs={10}>
                                <SimpleModal modal={modal} setModal={setModal} />
                                    {!advancedSearchMode && identifier()}
                                    {advancedSearchMode && nameField()}
                                <div className="newSearchPatient-controlOptions">
                                    <div className="newSearchPatient-advancedSwitch">
                                        {switchModeButton()}
                                    </div>
                                    <div className="newSearchPatient-birthdateField">
                                        {advancedSearchMode && birthDateField()}
                                    </div>
                                    <div className="newSearchPatient-birthdateField">
                                        {advancedSearchMode && genderField()}
                                    </div>

                                    <div className="newSearchPatient-searchButton">
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            style={{ background: '#08824A' }}
                                            startIcon={<HomeIcon />}
                                            onClick={searchPatient}>
                                            {t('NewSearchPatient-searchPatient')}
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </CustomTab>
                {selectHealthUnitModal()}
            </div>
            <div className="newSearchPatient-section newSearchPatient-fullHeight">    
                {loading && <Loading />}   
                {showResultList && <NewPatientsList
                                      patientsToList={patientsToList}
                                      selectPatient={
                                        (patientDoc) => {
                                          if (!patientDoc) {
                                            toast.error(t('NewSearchPatient-invalidIdentifier'));
                                            return;
                                          }
                                          setSearchDoc(patientDoc);
                                          simpleSearch(patientDoc)
                                        }
                                      } />}
                {showResult && <NewPatientFoundInfoAndAccess
                    patientInfo={selectedPatient} commonOrgs={commonCnes} allPatientCnes={allPatientCnes} />}
            </div>
        </div>
    )

}

export { NewSearchPatient };