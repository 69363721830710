import jwtDecode from 'jwt-decode';
import base64 from 'base-64';
import { fetchWithTimeout, logDebug, retrieveData } from 'utils/AuxiliarFunctions';
import { endpoints } from 'configs';

const getApplicationToken = async function () {
  logDebug('GENERATING OAUTH2 TOKEN');
  const myHeaders = new Headers();
  const portalBasicAuth = base64.encode(`${endpoints.portalConsumerKey}:${endpoints.portalConsumerSecret}`);
  // eslint-disable-next-line max-len
  myHeaders.append('Authorization', `Basic ${portalBasicAuth}`);
  myHeaders.append('Accept', '*/*');
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const raw = 'grant_type=client_credentials';
  // const raw = 'grant_type=client_credentials&validity_period=18000000';//18000000 milissec = 5 hours
  const url = `${endpoints.token_api}`;
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const applicationToken = await fetchWithTimeout(url, requestOptions, 4000).then((response) => {
    if (response.status !== 200) throw new Error(response.status);
    return (response);
  }).catch((err) => {
    logDebug(`oauth2Auth ERROR: ${err}`);
    switch (err) {
      case (500):
        return 'internal_error';
      case (400):
        return 'bad_request';
      default:
        return ('network_error');
    }
  });

  if (typeof applicationToken === 'string') return {};

  const responseJson = await applicationToken.json();
  return responseJson;
};

const validateOAuth = async function ({ code, bearerToken, provider }) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${bearerToken}`);
  myHeaders.append('Content-Type', 'application/json');

  const url = `${endpoints.OAUTH}/token?provider=${provider}&code=${code}`;
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const userResponse = await fetchWithTimeout(url, requestOptions)
    .then((response) => {
      if (response.status !== 200) throw new Error(response.status);
      return response.json();
    })
    .catch((err) => {
      switch (err) {
        case (500):
          return 'internal_error';
        case (400):
          return 'bad_request';
        default:
          return ('network_error');
      }
    });
  return userResponse;
};

export const getOAuthUser = async function (code) {
  const apimToken = await getApplicationToken(); // Guarda oauth2_token
  const bearerToken = apimToken.access_token;
  const oAuthProvider = await retrieveData('provider');

  const oAuthValidation = await validateOAuth({ code, bearerToken, provider: oAuthProvider});

  if (typeof oAuthValidation === 'string') throw new Error('Falha na validação do código OAuth.');
  const idToken = oAuthValidation.id_token;

  //
  // let expires_in = oAuthValidation.expires_in * 1000;
  // console.log("default: " + oAuthValidation.expires_in + "\nCorrigido: " + expires_in);
  // setInterval(async () =>  {
  //   xdsToken.getTokenRefresh(true);
  //   expires_in = oAuthValidation.expires_in * 1000;
  //   console.log(oAuthValidation.expires_in);
  // },expires_in); //60000 = 1 min
  //

  let idObject = jwtDecode(idToken);
  if (idObject) {
    idObject = { ...idObject, ...oAuthValidation };
    idObject.cpf = oAuthValidation.additional_data?.linked_cpf
      ? oAuthValidation.additional_data?.linked_cpf
      : idObject.preferred_username;
    return idObject;
  }
  return null;
};

export const getAccessfromID = async function (id_token) {
  logDebug('CHECKING IF USER EXISTS');
  const url = `${endpoints.OAUTH}/id_token?id_token=${id_token}`;

  // eslint-disable-next-line no-undef
  const myHeaders = new Headers();
  const apimToken = await getApplicationToken(); // Guarda oauth2_token
  const bearerToken = apimToken.access_token;
  myHeaders.append('Authorization', `Bearer ${bearerToken}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const userQueryResponse = await fetchWithTimeout(url, requestOptions)
    .then((response) => {
      if (response.status !== 200) throw new Error(response.status);
      return response.json();
    })
    .then((response) => response)
    .catch((err) => {
      logDebug(`userQueryResponse ERROR: ${err}`);
      switch (err.message) {
        case ('500'):
          return 'internal_error';
        case ('400'):
          return 'bad_request';
        case ('401'):
          return 'Unauthorized';
        default:
          return ('network_error');
      }
    });
  logDebug(`GET ACCESS FROM ID: ${JSON.stringify(userQueryResponse)}`);
  if (userQueryResponse.status === 'Success') return userQueryResponse;
  return false;
};
