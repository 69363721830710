import React, { useEffect, useState } from 'react';
import consentRequests from 'services/consentRequests';
import { retrieveData } from 'utils/AuxiliarFunctions';
import userRequests from 'services/userRequests';
import { SimpleModal, Loading } from 'components';
import { useVisualizerContext } from 'contexts/VisualizerContext';
import { DropdownOrganization } from 'components/DropdownOrganization';

import './NewConsentsPage.css';
import { CustomTab } from 'components';
import { Button } from '@material-ui/core';
import { useSelector } from "react-redux";
import i18n from "i18n";
import { useTranslation } from "react-i18next";

export function NewConsentsPage() {

    const [consents, setConsents] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showHealthUnitModal, setShowHealthUnitModal] = useState(false);
    const { setVisualizerPatient } = useVisualizerContext();
    const selectedOrgId = useSelector(state => state.selectedId);
    const orgs = useSelector(state => state.orgs);
    const { t } = useTranslation('NewConsentsPage', { i18n });
    const [currentPatId, setCurrentPatId] = useState(null);

    useEffect(() => {
        setLoading(true);

        const load = async () => {
            const username = await retrieveData('username');
            const consentsReceivedOuter = await consentRequests.getConsentReceived(username);
            const consentsReceived = consentsReceivedOuter[0];
            if (typeof consentsReceived === 'string') {
                setError(consentsReceived);
                return
            }
            for (var i = 0; i < consentsReceived.length; i++) {
                var consent = consentsReceived[i];
                const patient = await userRequests.getPatient(consent.pat_id);
                if (patient.name) {
                    consent.patientName = patient.name[0].text;
                }
            }

            setLoading(false);
            setConsents(consentsReceived);
        }

        load();

    }, []);

    const selectHealthUnitModal = () => (
        <SimpleModal
            setModal={() => setShowHealthUnitModal(false)}
            noCancelButton={true}
            okButton={true}
            modal={{
                    title: t('NewConsentsPage-chooseOrgToContinue') + ":",
                    body: (
                        <DropdownOrganization showTitle={false} style={{ width: '60%' }} />
                    ),
                    visible: showHealthUnitModal
                }}
            onOkSelected={() => { setLoading(true); setVisualizerPatient({ patientId: currentPatId, orgId: selectedOrgId } )}}
            />
    )  

    return (
        <div className='newConsentsPage-section'>
            <CustomTab type="share" title={t('NewConsentsPage-sharedConsents')}>
                {loading && <Loading />}   
                {error != '' && {error}}
                {!error && !loading && consents && (consents.length > 0) && <table className="newConsentsPage-table">
                        <tr>
                            <th>{t('NewConsentsPage-patientName')}</th>
                            <th>CPF/CNS</th>
                            <th>{t('NewConsentsPage-patientDate')}</th>
                            <th>{t('NewConsentsPage-patientExpiration')}</th>
                            <th></th>
                        </tr>
                    {consents.map((consent) => (
                        <tr>
                            <td>{consent.patientName}</td>
                            <td>{consent.pat_id}</td>
                            <td>{consent.date}</td>
                            <td>{t('NewConsentsPage-patientExpirationRevoke')}</td>
                            <td><Button 
                                variant='contained'
                                color='primary'
                                style={{ background: '#08824A' }}
                                onClick={(e) => {
                                    if (selectedOrgId == 0) {
                                        setCurrentPatId(consent.pat_id);
                                        setShowHealthUnitModal(true);
                                        return;
                                    }
                                    let orgCnes = selectedOrgId;
                                    setVisualizerPatient({ patientId: consent.pat_id, orgId: orgCnes });
                                }}>{t('NewConsentsPage-patientSelect')}</Button></td>
                        </tr>
                    ))}
                </table>}
                {!error && !loading && (!consents || consents ) && <span className="newConsentsPage-totalConsents">
                    Total de compartilhamentos: 0
                    </span>}
            </CustomTab>
            {selectHealthUnitModal()}
        </div>
    )

}