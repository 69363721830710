import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
} from '@material-ui/core';
import { formatDateDisplay } from 'utils/dateFunctions';
import { translate } from 'utils/AuxiliarFunctions';
import { useTranslation } from 'react-i18next';

function MedicationsDispensedTabContent({ documents, selectReport }) {
  const { t } = useTranslation('PatientSummary');
  const headers = [t('myRegisters-patientSummary-healthSummary-tab-column-quantity'),
                    t('myRegisters-patientSummary-healthSummary-tab-column-unity'), 
                    t('myRegisters-patientSummary-healthSummary-tab-column-description'), 
                    t('myRegisters-patientSummary-healthSummary-tab-column-date')];
  const [rows, setRows] = useState([]);
  
  useEffect(function() {
    var rowsIteration = [];
    documents.forEach(document => {
      var newRow = {};
      newRow.id = document.id;
      newRow.quantity = document.quantity.value;
      newRow.date = formatDateDisplay(document.whenHandedOver);

      document.contained.forEach(function(containedItem) {
        if (containedItem.resourceType == 'Medication') {
          if (containedItem.form) newRow.unit = translate(containedItem.form.coding[0].display);
          if (containedItem.code) newRow.description = translate(containedItem.code.coding[0].display);
        }
      });

      rowsIteration.push(newRow);
    });

    setRows(rowsIteration);

  }, []);

  return (
    <Table
      size="small"
      aria-label="enhanced table"
    >
      <TableHead>
        <TableRow>
          {headers.map((header, i) => (
            <TableCell
              key={i}
            >
              <h5>
                {header}
              </h5>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map((document) => (
            <Tooltip
              key={document.id}
              placement="top"
              arrow
              title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
            >
              <TableRow
                hover
                onClick={() => {}}
              >
                <TableCell padding="checkbox">{document.quantity}</TableCell>
                <TableCell padding="checkbox">{document.unit}</TableCell>
                <TableCell>{document.description}</TableCell>
                <TableCell padding="checkbox">{document.date}</TableCell>
              </TableRow>
            </Tooltip>
          ))
        }
      </TableBody>
    </Table>
  );
}

MedicationsDispensedTabContent.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectReport: PropTypes.func.isRequired,
};

export { MedicationsDispensedTabContent };
