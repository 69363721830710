import fhirRequests from 'services/fhirRequests';
import MHDRequests from 'services/MHDRequests';
import profileRequests from 'services/profileRequests';
import { roles as rolesDictionary, features, oids } from 'configs';
import {
  formatName,
  getFromPromiseAllSettled,
  logDebug, retrieveData, storeData, verifyCPF,
} from './AuxiliarFunctions';
import { getIbgeCityById } from './externalAPIs';
import {
  filtersOutResourceWithEmptyCode,
  getRolesFromPractitionerRoles,
} from './fhirParser';
import consentRequests from 'services/consentRequests';

const roleDict = {
  ROLE_PRACTITIONER: 'ROLE_PRACTITIONER',
};

export const fetchUserRole = async (orgAndRole) => {
  const organizationRole = orgAndRole.split('-'); // 'CNES-ROLE'
  const organization = organizationRole[0];
  const role = organizationRole[1];
  const org = await MHDRequests.getOrganization(organization);
  const selectedRoleToWrite = {
    organization,
    name: role,
    value: orgAndRole,
    organization_object: org,
    organization_address: org?.address ? org.address[0] : null,
  };
  await storeData('selectedRole', JSON.stringify(selectedRoleToWrite));
  return selectedRoleToWrite;
};

export const fetchUserData = async () => {
  try {
    const username = await retrieveData('username');
    const userMhd = await MHDRequests.getPatientJson({patientId: username});

    const userApim = await profileRequests.getProfileData(username);
    const identifier = getPractitionerIdentifier(userMhd);
    const roles = features.doNotGetPractitionerRoles ? null : await getPractitionerRoles(identifier);
    // const userRequest = await fetchUserRequest(username);
    const userDataToWrite = {
      mhd: userMhd,
      apim: userApim,
      rolesPerOrganization: roles,
      // userRequest,
    };
    return userDataToWrite;
  } catch (err) {
    logDebug('erro');
    return null;
  }
};

function getPractitionerIdentifier(userMhd) {
  try {
    const practitionerIdentifier = userMhd?.id;
    return practitionerIdentifier;
  } catch (err) {
    return null;
  }
}

async function getPractitionerRoles(identifier) {
  if (identifier) {
    const fhirResponse = await fhirRequests.getPractitionerRoles(
      { identifier },
    );
    if (typeof fhirResponse === 'string') throw Error(fhirResponse);
    const practitionerRoles = filtersOutResourceWithEmptyCode(fhirResponse);
    let roles = getRolesFromPractitionerRoles(practitionerRoles);
    roles = setRoleLabels(roles);
    roles = await setOrganizationData(roles);
    return roles;
  }
  return null;
}

async function setOrganizationData(roles) {
  if (!roles) return null;
  const rolesPromise = await Promise.allSettled(roles?.map(async (role) => {
    const rolesReturn = role;
    const response = await MHDRequests.getOrganization(role.id);
    rolesReturn.organizationName = response.alias[0];
    rolesReturn.address = response.address ? response.address[0] : '';
    return rolesReturn;
  }));
  return getFromPromiseAllSettled(rolesPromise);
}

function setRoleLabels(roles) {
  roles = roles?.map((role) => ({
    id: role?.id,
    roles: role?.code?.map((coding) => ({
      system: coding?.system,
      code: coding?.code,
      display: roleDict[coding?.code],
    })),
  }));
  return roles;
}

export async function extractMainUserData(userData) {
  const apimData = extractApimData(userData.apim);
  const mhdData = extractMHDData(userData.mhd);
  const address = await extractUserAddress(userData.userRequest);
  
  let allRoles = []
  let role = {};
  if (Array.isArray(userData.rolesPerOrganization)) {
    userData.rolesPerOrganization.map((item) => item.roles).forEach((rolesList) => {
      allRoles = [...allRoles, ...rolesList];
    });
    allRoles.forEach((item) => {
      role[rolesDictionary[item.code]] = true;
    });
  }
  return {
    ...apimData, ...mhdData, address, role,
  };
}

function extractApimData(response) {
  try {
    let phone = '';
    let email = '';

    if (response.Resources) {
      phone = response.Resources[0].phoneNumbers;
      email = response.Resources[0]?.emails?.[0] || [];
      
      let organizationIds = [];
      const { entitlements } = response.Resources[0];
      
      if (entitlements) {
        for (const index in entitlements) {
          organizationIds = (
            response.Resources[0].entitlements[index].value);
          }
        }   
      storeData('cnes', organizationIds);
    }

    return {
      email,
      phone,

    };
  } catch (err) {
    err.name = 401;
    logDebug(err);

    throw err;
  }
}

function extractMHDData(response) {
  try {
    let name = response.name.find((item) => item.use === 'official').text;
    name = formatName(name);

    const { gender } = response;
    let username = response.identifier.find(
      (item) => item?.type?.text === 'CPF',
    )?.value;
    if (!username) {
      username = response.identifier.find(
        (item) => item?.system === `urn:oid:${oids.cpf}`,
      )?.value;
    }

    const cpf = verifyCPF(username) ? username.replace(/\D/g, '') : '';
    const birthday = response.birthDate;
    const cns = response.identifier.find(
      (item) => item?.type?.text === 'CNS' && item?.use === 'official',
    )?.value;

    return {
      name,
      gender,
      username,
      birthday,
      cns,
      cpf,
    };
  } catch (err) {
    err.name = 401;
    logDebug(err);

    throw err;
  }
}

// async function fetchUserRequest(userID) {
//   try {
//     const responseUser = await userRequests.getPatient(userID);
//     checkResponseFhir(responseUser);
//     return responseUser;
//   } catch (err) {
//     return null;
//   }
// }

async function extractUserAddress(userData) {
  try {
    const prevAddress = userData?.address[0];
    const cityResponse = await getIbgeCityById(
      {
        city_id: prevAddress.city,
        state_acronym: prevAddress.state,
      },
    );
    const whereIsDistrict = 3;

    const defaultFormValues = {
      address: prevAddress?.line[0] || '',
      complement: prevAddress?.line[2] || '',
      city: cityResponse,
      country: 'Brasil',
      neighborhood: prevAddress?.line[whereIsDistrict] || '',
      number: prevAddress?.line[1] || '',
      postalCode: prevAddress.postalCode,
      state: prevAddress?.state,
    };
    return defaultFormValues;
  } catch (err) {
    return null;
  }
}

export async function loadOrgsProfessional(placeholder) {
  let orgsLocal = [];
  const cnesProfessional = await fhirRequests.getCnes();
  let idCount = 1;
  if (cnesProfessional.length > 1) {
      orgsLocal.push({id: 0, cnes: 0, name: placeholder});
  }
  for (let i = 0; i < cnesProfessional.length; i++) {
      let cnesItem = cnesProfessional[i];
      const orgName = await MHDRequests.getOrganizationName(cnesProfessional);              
      orgsLocal.push({id: idCount++, cnes: cnesItem, name: orgName + " - " + cnesItem});
  }
  if (orgsLocal.length == 2) {
      orgsLocal.shift();
  }

  return orgsLocal;
}

export async function getAllPatientCnes(patientId) {
  let allCnesPatient = [];
  try {
    const cnesPatient = await consentRequests.getPatientCNES(patientId);
    const cnesFiltered = Array.isArray(cnesPatient)
      ? cnesPatient.filter((cnes) => cnes !== null) : [];
    allCnesPatient = [...cnesFiltered];
  } catch (err) {
    logDebug('SearchPatient getAllPatientCnes :>>', err);
  }
  return allCnesPatient;
}
