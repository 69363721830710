import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDateDisplay } from 'utils/dateFunctions';
import { Button } from '@material-ui/core';
import { Loading, SimpleModal } from 'components';
import { useVisualizerContext } from 'contexts/VisualizerContext';
import { useSelector } from "react-redux";
import { DropdownOrganization } from 'components/DropdownOrganization';
import EmergencyAccessButton from 'pages/SearchPatient/EmergencyAccessButton';
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import { useUserContext } from 'store/UserContext';
import { NewConsentTerm } from 'components/NewConsentTerm';
import { PopUp } from 'components/PopUp';
import consentRequests from 'services/consentRequests';
import { retrieveData, findCommonElements } from 'utils/AuxiliarFunctions';
import { getAllPatientCnes } from 'utils/userFunctions';
import fhirRequests from 'services/fhirRequests';

import './NewPatientFoundInfoAndAccess.css';

export function NewPatientFoundInfoAndAccess({ patientInfo, commonOrgs, allPatientCnes }) {
    const { setVisualizerPatient } = useVisualizerContext();
    const [showHealthUnitModal, setShowHealthUnitModal] = useState(false);
    const selectedOrgCnes = useSelector(state => state.selectedId);
    const orgs = useSelector(state => state.orgs);
    const { t } = useTranslation('NewPatientFoundInfoAndAccess', { i18n });
    const [selectedOrgOid, setSelectedOrgOid] = useState(0);
    const [showPopupTermsLGPD, setShowPopupTermsLGPD] = useState(false);
    const [isPopupTermsLGPDAccepted, setIsPopupTermsLGPDAccepted] = useState(false);
    const [professional] = useUserContext();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(function() {
      const keyPopupTermsLGPDAccepted = JSON.parse(sessionStorage.getItem('keyPopupTermsLGPDAccepted'));

      if (keyPopupTermsLGPDAccepted) {
        setIsPopupTermsLGPDAccepted(true);
      }

    }, []);

    async function regularAccess(commonOrgsCheck) {
        if (selectedOrgCnes == 0) {
            setShowHealthUnitModal(true);
        } else {
            startRegularAccess(commonOrgsCheck);
        }
    }

    function startRegularAccess(commonOrgsCheck) {
        if (selectedOrgCnes > 0) {
            setIsLoading(true);
            const commonOrgsMatch = commonOrgsCheck.includes(selectedOrgCnes);

            if (commonOrgsMatch) {
                setVisualizerPatient({ 
                    patientId: patientInfo.identifier, 
                    purposeOfUse: 'Atendimento', 
                    orgId: selectedOrgCnes 
                });
            } else {
                const selectedProfessionalOrg = orgs.filter(org => org.id == selectedOrgCnes)[0];
                setSelectedOrgOid(selectedProfessionalOrg.organizationOid);
                if (!isPopupTermsLGPDAccepted) {
                    setShowPopupTermsLGPD(true);
                    setIsLoading(false);
                } else {
                    makeConsent();
                    reCheckConsent();
                }
            }
        } else {
            setIsLoading(false);
        }
    }

    const selectHealthUnitModal = () => (
        <SimpleModal
            setModal={() => setShowHealthUnitModal(false)}
            noCancelButton={true}
            okButton={true}
            modal={{
                    title: "Selecione um estabelecimento de saúde para prosseguir:",
                    body: (
                        <DropdownOrganization showTitle={false} style={{ width: '60%' }} />
                    ),
                    visible: showHealthUnitModal
                }}
            onCloseModal={true}
            onOkSelected={() => {
                setIsLoading(true); 
                startRegularAccess(commonOrgs);
            }}
        />
    )
    

    const reCheckConsent = async () => {
        const allPatientCnes = await getAllPatientCnes(patientInfo.identifier);

        const allProfessionalCnes = await fhirRequests.getCnes();
        
        const commonCnes = findCommonElements(allPatientCnes,
            allProfessionalCnes);
        
        regularAccess(commonCnes);
    }

    const makeConsent = async () => {
        setIsLoading(true);
        const subject_id = await retrieveData('username');
        if (patientInfo.identifier === subject_id) {
            return null;
        }
    
        const orgSelected = orgs.filter(org => org.id == selectedOrgCnes)[0];
        await consentRequests.appc_request_org('Permit', subject_id, [patientInfo.identifier], orgSelected.id, orgSelected.organizationOid);
    }

    const savePopupTermsLGPDAccepted = () => {
        sessionStorage.setItem('keyPopupTermsLGPDAccepted', JSON.stringify(true));
    }

    const popupTermoLGPD = () => {
        const orgSelected = orgs.filter(org => org.id == selectedOrgCnes)[0];
        const orgSelectedName = orgSelected.organizationName;

        return (
            <div>
                <PopUp handleClose={() => { setShowPopupTermsLGPD(false); }}>
                    <NewConsentTerm
                        professionalName={professional.name}
                        professionalCpf={professional.cpf}
                        locationService={orgSelectedName}
                        />
                    <br />
                    <div style={{ textAlign:'right' }}>
                        <button onClick={() => {
                                setShowPopupTermsLGPD(false);
                            }} 
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained button-disabled">{t('NewPatientFoundInfoAndAccess-consentButton-decline')}
                        </button>
                        <button onClick={() => {
                                // envia requisição de consentimento para o back-end
                                makeConsent();
                                setShowPopupTermsLGPD(false);
                                savePopupTermsLGPDAccepted();
                                reCheckConsent();
                            }} 
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained button-decline"
                            style={{ marginLeft:'5px' }}>{t('NewPatientFoundInfoAndAccess-consentButton-accept')}
                        </button>
                    </div>
                </PopUp>
           </div>
        )
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <>
            <div>
                <div>
                    <table>
                        <tr>
                            <th>{t('NewPatientFoundInfoAndAccess-patientName')}</th>
                            <th>CPF/CNS</th>
                            <th>{t('NewPatientFoundInfoAndAccess-patientBirthdate')}</th>
                            <th>{t('NewPatientFoundInfoAndAccess-patientGender')}</th>
                        </tr>
                        <tr>
                            <td>{ patientInfo.name }</td>
                            <td>{ patientInfo.identifier }</td>
                            <td>{ formatDateDisplay(patientInfo.birthdate, true) }</td>
                            <td>{ patientInfo.gender }</td>
                        </tr>
                    </table>
                </div>
                <div className="newPatientFoundInfoAndAccess-acess">
                    <table>
                        <tr>
                            <th colspan="2">{t('NewPatientFoundInfoAndAccess-accessType')}</th>
                        </tr>
                        <tr>
                            <td><Button
                                    variant="contained"
                                    color="primary"
                                    style={{ background: '#08824A' }}
                                    onClick={(e) => {
                                        regularAccess(commonOrgs);
                                    }}
                                    >
                                {t('NewPatientFoundInfoAndAccess-access')}
                                </Button>
                            </td>
                            <td>
                                <EmergencyAccessButton
                                    patientIdEmergency={patientInfo.identifier}
                                    />
                            </td>
                        </tr>
                    </table>
                </div>
                {selectHealthUnitModal()}
                {showPopupTermsLGPD && popupTermoLGPD()}
            </div>
        </>
    );

}

NewPatientFoundInfoAndAccess.propTypes = {
    patientInfo: PropTypes.shape({
        name: PropTypes.string.isRequired,
        birthdate: PropTypes.string.isRequired,
        gender: PropTypes.string.isRequired,
        identifier: PropTypes.string.isRequired
    }).isRequired,
    patientOrgs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        cnes: PropTypes.string.isRequired
    }))
}