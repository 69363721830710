import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { 
    Box, 
    Button, 
    Paper, 
    Typography 
} from '@material-ui/core';

import useStyles from './styles';
import AdminFhirRequests from 'services/AdminFhirRequests';

import { Loading, NoReports } from 'components';
import ReportModal from '../ReportModal/ReportModal';

const AppointmentTab = ({ patientId }) => {
    const classes = useStyles();
        
    const [is_loading, setIsLoading] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [modal_open, setModalOpen] = useState(false);
    const [current_appointment, setCurrentAppointment] = useState(null);

    const getData = async () => {
        setIsLoading(true);
        const resp = await AdminFhirRequests.getAppointments(patientId);
        if (resp?.entry) {
            const data = resp.entry.map((entry) => entry.resource);
            setAppointments(data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        (async () => await getData())();
    }, [])

    const getStatus = (status) => {
        switch (status) {
            case 'booked':
                return 'Agendado';
            case 'cancelled':
                return 'Cancelado';
            case 'fulfilled':
                return 'Atendido';
            default:
                return '';
        }
    }

    const getCBO = (appointment) => {
        return appointment.contained.find((container) => container.resourceType === 'PractitionerRole');
    }

    return (
        <>
            <Box>
                {is_loading && <Loading type="linear" />}
                {(appointments.length === 0 && !is_loading) 
                    ? <NoReports description="O paciente não possui agendamentos" />
                    : appointments.map((item) => (
                        <Button 
                            style={{width: '100%'}}
                            onClick={() => {
                                setModalOpen(true);
                                setCurrentAppointment(item);
                            }}
                        >
                            <Paper
                                className={classes.paper}
                            >
                                <Typography 
                                    variant='body2'
                                    className={classes.fwBold}
                                >
                                    {
                                        item.contained.find((container) => container.resourceType === 'Organization')?.alias[0] ||
                                        item.contained.find((container) => container.resourceType === 'Organization')?.name || 
                                        item.serviceType[0]?.coding[0]?.display ||
                                        ""
                                    }
                                </Typography>
                                <Typography 
                                    variant='body2'
                                >
                                    Local: {item.contained.find((container) => container.resourceType === 'Location')?.name}
                                </Typography>
                                <Typography 
                                    variant='body2'
                                >
                                    Data: {moment(item.start).format('DD/MM/YYYY [-] HH:mm')}
                                </Typography>
                                <Typography 
                                    variant='body2'
                                >
                                    Status: {getStatus(item.status)}
                                </Typography>
                            </Paper>
                        </Button>
                    ))
                }
            </Box>
            <ReportModal
                show={modal_open}
                setShow={setModalOpen}
                title="Detalhes do Agendamento"
                body={(
                    <div className={classes.bodyWrapper}>
                        <Row>
                            {current_appointment?.serviceType[0]?.coding[0] && (
                                <>
                                    <Col xs={4} lg={3}>
                                        <Typography 
                                            variant='body1'
                                        >
                                            Procedimento
                                        </Typography>
                                    </Col>
                                    <Col xs={8} lg={9}>
                                        <Typography
                                            className={classes.detailData}
                                            variant='body2'
                                        >
                                            {current_appointment?.serviceType[0]?.coding[0]?.code} - {current_appointment?.serviceType[0]?.coding[0]?.display}
                                        </Typography>
                                    </Col>
                                </>
                            )}
                            <Col xs={4} lg={3}>
                                <Typography 
                                    variant='body1'
                                >
                                    Local
                                </Typography>
                            </Col>
                            <Col xs={8} lg={9}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                    {current_appointment?.contained.find((container) => container.resourceType === 'Location')?.name || ""}
                                </Typography>
                            </Col>
                            <Col xs={4} lg={3}>
                                <Typography 
                                    variant='body1'
                                >
                                    Data
                                </Typography>
                            </Col>
                            <Col xs={8} lg={9}>
                                <Typography
                                    className={classes.detailData}
                                    variant='body2'
                                >
                                    {current_appointment ? moment(current_appointment?.start).format('DD/MM/YYYY [-] HH:mm') : ""}
                                </Typography>
                            </Col>
                            {current_appointment && (
                                <>
                                    <Col xs={4} lg={3}>
                                        <Typography 
                                            variant='body1'
                                        >
                                            Status
                                        </Typography>
                                    </Col>
                                    <Col xs={8} lg={9}>
                                        <Typography
                                            className={classes.detailData}
                                            variant='body2'
                                        >
                                            {getStatus(current_appointment.status)}
                                        </Typography>
                                    </Col>
                                </>
                            )}
                            {current_appointment && (
                                <>
                                    <Col xs={4} lg={3}>
                                        <Typography 
                                            variant='body1'
                                        >
                                            CBO
                                        </Typography>
                                    </Col>
                                    <Col xs={8} lg={9}>
                                        <Typography
                                            className={classes.detailData}
                                            variant='body2'
                                        >
                                            {getCBO(current_appointment)?.code[0]?.coding[0]?.code} - {getCBO(current_appointment)?.code[0]?.coding[0]?.display}
                                        </Typography>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </div>
                )}
            />
        </>
    )
}

export default AppointmentTab