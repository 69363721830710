import { docTypes } from 'configs/env';
import { logDebug } from 'utils/AuxiliarFunctions';

const getDocumentCodes = ({ docType }) => {
  let codes = [];
  try {
    docTypes.forEach((item) => {
      if (docType === item?.name) codes = [...codes, ...item.codes];
    });
  } catch (err) {
    logDebug('getDocumentCode :>>', err);
  }

  codes = [...new Set(codes)];

  return codes;
};

const containsCode = (arr, str) => {
  let res = false;
  arr.forEach((item) => {
    if (item.toLowerCase().includes(str.toLowerCase())
    || str.toLowerCase().includes(item.toLowerCase())) res = true;
  });
  return res;
};

const getDocumentType = ({ docCode }) => {
  let docType = {
    name: docCode,
    alias: 'Doc',
    codes: [],
  };

  try {
    docTypes.forEach((item) => {
      if (containsCode(item.codes, docCode.toLowerCase())) {
        docType = {
          ...item,
        };
      }
    });
  } catch (err) {
    logDebug('getDocumentType :>>', err);
  }

  return docType;
};

export {
  docTypes, getDocumentCodes, getDocumentType,
};
